<template>
  <div class="dashboard body-bcg">
    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="32"
          :viewBox1="48"
          :viewBox2="48"
          iconName="home"
          iconColor="transparent"
          iconStroke="#4318ff"
          ><icon-home
        /></icon-base>

        <div class="page-title__text">
          <h1>Solutions Hub</h1>
          <span>Solutions Hub information</span>
        </div>
      </div>

      <div
        class="active-projects box-wrapper"
        :class="{ 'no-projects': groupedProducts.length === 0 }"
      >
        <div class="flex justify-between">
          <h2 class="section-title">Active Projects</h2>
          <div class="input-group input-group--small" :disabled="loading">
            <div
              class="position-r search-field"
              :class="{ focus: searchActive }"
            >
              <a-input
                placeholder="Search by project name"
                style="width: 240px"
                v-model="searchParameters"
                @focus="searchActive = true"
                @blur="searchActive = false"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParameters != ''"
                  @click="searchParameters = ''"
              /></a-input>
              <icon-base
                class="search-icon"
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#000"
                ><icon-search-thin
              /></icon-base>
            </div>
          </div>
        </div>

        <Loader
          v-if="loading"
          text="Loading active projects..."
          :paddingTop="130"
          :paddingBottom="130"
        ></Loader>
        <a-carousel
          v-else-if="groupedProducts.length > 0"
          arrows
          :dots="false"
          class="active-projects__carousel"
        >
          <div v-for="(productGroup, index) in groupedProducts" :key="index">
            <div class="active-projects__carousel-items-wrapper">
              <ProductItem
                v-for="item in productGroup"
                :key="item.SurveyID"
                :product="item"
                :user="user"
                :hideCopyBtn="true"
                :showBookmarkBtn="true"
                :loadingPinProject="loadingPinProjectID === item.SurveyID"
                class="shadow-0"
                @get-summary="getSummaryData($event)"
                @define-report="defineReport($event)"
                @show-survey-services-modal="
                  showSurveyServicesModal(
                    $event.surveyID,
                    $event.surveyName,
                    $event.modalType
                  )
                "
                @show-survey-clone-modal="
                  showSurveyServicesModal(
                    $event.surveyID,
                    $event.surveyName,
                    $event.modalType
                  )
                "
                @delete-survey="
                  deleteSurvey($event.surveyID, $event.surveyName)
                "
                @view-log="viewLog($event.surveyID, $event.surveyName)"
                @add-leg="addLeg($event, undefined)"
                @click="onProductClick($event, undefined)"
                @edit-status="openSurveyStatusModal($event, undefined)"
                @pin-project="pinProject($event)"
              ></ProductItem>
            </div>
          </div>
        </a-carousel>
        <div v-else class="active-projects__info">
          <icon-base
            :width="30"
            :height="30"
            :viewBox1="32"
            :viewBox2="32"
            iconName="no-results"
            iconColor="#415262"
            iconStroke="#fff"
            class="mr-10"
            ><icon-no-results
          /></icon-base>
          <p class="mb-0">
            It looks like you're just getting started! Start building by
            selecting a product below.
          </p>
        </div>
      </div>

      <div class="products box-wrapper mb-25">
        <h2 class="section-title">Launchpad</h2>
        <div class="products__grid">
          <div
            v-for="(item, index) in launchpad"
            :key="index"
            class="products__item"
          >
            <i class="color1">
              <icon-base
                :width="40"
                :height="40"
                :viewBox1="512"
                :viewBox2="512"
                iconName="dashboard"
                ><icon-dashboard /></icon-base
            ></i>
            <span class="products__item-number" :class="`color${index + 1}`"
              >0{{ index + 1 }}</span
            >

            <h3>{{ item.title }}</h3>
            <p>
              {{ item.description }}
            </p>
            <div class="flex gap-15">
              <a-button
                type="primary"
                :disabled="item.disableCreate"
                @click="initProject(item)"
              >
                {{ item.createBtnLabel || "Create" }}</a-button
              >
              <a-button
                type="link"
                :disabled="item.disableView"
                @click="viewProjects(item)"
                >View</a-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="contact box-wrapper">
        <div class="contact__info">
          <span>Need help?</span>
          <h3>
            Not finding what you're looking for? <br />
            Let's discuss what we can achieve together.
          </h3>
          <a-button type="primary" @click="contactModal = true"
            >Contact Us</a-button
          >
        </div>
      </div>
    </div>

    <a-modal
      title="Select a Folder"
      :visible="foldersModal"
      @ok="createProject"
      @cancel="foldersModal = false"
      :afterClose="scrollToTop"
      cancelText="Cancel"
      okText="Create"
      :okButtonProps="{ props: { disabled: activeFolderId === null } }"
      :width="360"
    >
      <Loader
        v-if="loadingFolders"
        text="Loading data..."
        :paddingTop="80"
        :paddingBottom="80"
      ></Loader>
      <div v-else>
        <p class="mb-25 font-size-16">
          To create a new project, please select a folder.
        </p>
        <ul class="folders">
          <li
            v-for="item in folders"
            :key="item.ProjectId"
            @click="selectFolder(item.ProjectId)"
            :class="{ 'is-active': activeFolderId === item.ProjectId }"
          >
            <i>
              <font-awesome-icon
                :icon="
                  activeFolderId === item.ProjectId ? 'folder-open' : 'folder'
                "
              />
            </i>
            {{ item.ProjectName }}
          </li>
        </ul>
      </div>
    </a-modal>

    <a-modal
      title="Need help?"
      :visible="contactModal"
      :okText="'Send'"
      @cancel="contactModal = false"
      @ok="sendContact"
      :width="400"
      :okButtonProps="{
        props: {
          disabled:
            contact.name === '' ||
            contact.message === '' ||
            contact.company === '' ||
            sendContactLoading,
        },
      }"
    >
      <p class="mb-20 font-size-16">
        Not finding what you're looking for? Let's discuss what we can achieve
        together.
      </p>

      <div class="input-group mb-15">
        <label>Name</label>
        <a-input
          class="fullWidth"
          type="text"
          v-model="contact.name"
          placeholder="Please enter your name..."
        />
      </div>
      <div class="input-group mb-15">
        <label>Company</label>
        <a-input
          class="fullWidth"
          type="text"
          v-model="contact.company"
          placeholder="Please enter your company name..."
        />
      </div>
      <div class="input-group mb-15">
        <label>Description</label>
        <a-textarea
          :auto-size="{ minRows: 5 }"
          v-model.trim="contact.message"
          placeholder="Please describe what you're looking for..."
        />
      </div>
      <div class="send-contact-loader" v-if="sendContactLoading">
        <Loader text="Sending..."></Loader>
      </div>
    </a-modal>

    <a-modal
      :title="'Field summary for ' + activeSurveyName"
      :visible="modals.inFieldSurveySummary"
      @cancel="modals.inFieldSurveySummary = false"
      :footer="null"
      :width="720"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div v-if="inFieldSummaryData.message.rows.length !== 0">
        <table class="infield-summary-table">
          <tbody>
            <tr>
              <td>
                <strong
                  class="mr-5"
                  v-html="
                    inFieldSummaryData.message.rows[
                      inFieldSummaryData.message.rows.length - 1
                    ].name
                  "
                ></strong>
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav ml-5"
                >
                  <template slot="title">
                    <span
                      >{{
                        inFieldSummaryData.message.rows[
                          inFieldSummaryData.message.rows.length - 1
                        ].title
                      }}
                    </span>
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </td>
              <td>
                {{
                  inFieldSummaryData.message.rows[
                    inFieldSummaryData.message.rows.length - 1
                  ].formatted
                }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in inFieldSummaryData.message.rows"
              :key="index"
            >
              <td>
                <strong v-html="item.name" class="mr-5"></strong>
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--step-nav"
                >
                  <template slot="title">
                    <span>{{ item.title }} </span>
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </td>
              <td>{{ item.formatted }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      title="Study summary"
      :visible="modals.packTestSummary"
      @cancel="studySummaryCloseModal"
      :footer="null"
      :width="920"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div v-if="surveySummary !== null && surveySummary !== ''">
        <a-row :gutter="16" class="flex mb-25">
          <a-col
            :span="12"
            style="width: 52%"
            v-if="
              surveySummary !== null &&
              surveySummary.activeShelf !== undefined &&
              surveySummary.activeShelf.shelfName !== null
            "
          >
            <ActiveShelf
              :showEdit="false"
              :showRetailer="true"
              :activeShelf="surveySummary.activeShelf"
              class="h-full"
            ></ActiveShelf>
          </a-col>
          <a-col
            :span="12"
            style="width: 48%"
            v-if="
              surveySummary !== null &&
              surveySummary.selectedProducts !== undefined &&
              surveySummary.selectedProducts.length
            "
          >
            <SelectedProducts
              :showEdit="false"
              :selectedProducts="surveySummary.selectedProducts"
            ></SelectedProducts
          ></a-col>
        </a-row>
        <div class="survey-summary__details">
          <a-descriptions
            bordered
            :column="2"
            v-if="surveySummary !== null && surveySummary !== ''"
          >
            <a-descriptions-item label="Name">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.SurveyName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Country">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.Country
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Category">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CategoryName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Brand">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.BrandName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Leg(s)">
              <ul class="mb-0 pl-0">
                <li v-for="(item, index) in uniqueFriendlyNames" :key="index">
                  {{ item }}
                  <br v-if="index < uniqueFriendlyNames.length" />
                </li>
              </ul>
            </a-descriptions-item>

            <a-descriptions-item label="Deep Dive Product(s)">
              <ul
                v-if="
                  surveySummary !== null &&
                  surveySummary.deepDiveProducts !== undefined &&
                  surveySummary.deepDiveProducts.length
                "
                class="pl-0 mb-0"
              >
                <li
                  v-for="(item, index) in surveySummary.deepDiveProducts"
                  :key="index"
                >
                  {{ item.productName
                  }}<span
                    v-if="index + 1 < surveySummary.deepDiveProducts.length"
                    >,</span
                  ><span
                    v-if="
                      index + 1 === surveySummary.deepDiveProducts.length &&
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                    >,</span
                  >
                  <br v-if="index < surveySummary.deepDiveProducts.length" />
                </li>
              </ul>
              <span v-else>/</span>
              <ul
                v-if="
                  surveySummary !== null &&
                  surveySummary.uniqueCompetitvedeepDiveProducts !==
                    undefined &&
                  surveySummary.uniqueCompetitvedeepDiveProducts.length
                "
                class="pl-0 mb-0"
              >
                <li
                  v-for="(
                    item, index
                  ) in surveySummary.uniqueCompetitvedeepDiveProducts"
                  :key="index"
                >
                  {{ item
                  }}<span
                    v-if="
                      index + 1 <
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                    >,</span
                  >
                  <br
                    v-if="
                      index <
                      surveySummary.uniqueCompetitvedeepDiveProducts.length
                    "
                  />
                </li>
              </ul>
            </a-descriptions-item>
            <a-descriptions-item label="Communication objective(s)">
              {{
                surveySummary !== null &&
                surveySummary.SurveyConfig.CommunicationObjectiveName1 !== null
                  ? surveySummary.SurveyConfig.CommunicationObjectiveName1
                  : "/"
              }}<span
                v-if="
                  surveySummary !== null &&
                  surveySummary.SurveyConfig.CommunicationObjectiveName2 !==
                    null
                "
                >,</span
              >
              {{
                surveySummary !== null &&
                surveySummary.SurveyConfig.CommunicationObjectiveName2 !== null
                  ? surveySummary.SurveyConfig.CommunicationObjectiveName2
                  : ""
              }}
            </a-descriptions-item>

            <a-descriptions-item label="Sample per deep dive">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.audience.sampleSize
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item :span="2" label="Emotional attributes">
              <div
                v-if="
                  surveySummary !== null &&
                  surveySummary.AttributeDetails !== undefined &&
                  surveySummary.AttributeDetails.length
                "
                class="pl-0 mb-0"
              >
                <span
                  v-for="(item, index) in surveySummary.AttributeDetails"
                  :key="index"
                >
                  {{ item.AttributeLabel
                  }}<span
                    v-if="index + 1 < surveySummary.AttributeDetails.length"
                    >,</span
                  >
                </span>
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="Cost center">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CostCenter !== ""
                    ? surveySummary.SurveyConfig.CostCenter
                    : "/"
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Estimate completion date">
              {{ estimateCompletionDate }}
            </a-descriptions-item>
            <a-descriptions-item label="Custom Questions" :span="2">
              <template v-if="customQuestionsList !== null">
                <ol
                  class="mb-0 pl-15"
                  v-if="customQuestionsList.CustomQuestions.length > 0"
                >
                  <li
                    v-for="(item, index) in customQuestionsList.CustomQuestions"
                    :key="index"
                  >
                    {{ item.questionText }}
                  </li>
                </ol>
                <span v-else>/</span>
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <div
          class="survey-summary__details survey-summary__details--total mt-25"
        >
          <a-descriptions bordered :column="2">
            <a-descriptions-item label="Total price">
              <span class="flex items-center">
                ${{ formatPrice(surveySummaryPrice.total) }}
                <a-tooltip
                  placement="top"
                  overlayClassName="ant-tooltip--small"
                >
                  <template slot="title">
                    <span
                      >Total price is a dynamic calculation that ensures a
                      readable sample base for fully assessing your pack designs
                      and communication objectives. This price is impacted by
                      number of legs, number of deep dives and the specific
                      category.</span
                    >
                  </template>

                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      title="Study summary"
      :visible="modals.mapMakerTestSummary"
      @cancel="
        (modals.mapMakerTestSummary = false), (estimateCompletionDate = null)
      "
      :footer="null"
      :width="920"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <div v-if="surveySummary !== null && surveySummary !== ''">
        <div class="survey-summary__details" v-if="modals.mapMakerTestSummary">
          <a-descriptions
            bordered
            :column="2"
            v-if="surveySummary !== null && surveySummary !== ''"
          >
            <a-descriptions-item label="Name">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.SurveyName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Country">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.Country
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Category">
              {{
                surveySummary !== null && surveySummary !== ""
                  ? surveySummary.SurveyConfig.CategoryName
                  : "/"
              }}
            </a-descriptions-item>
            <a-descriptions-item label="Brands">
              <ul
                class="mb-0 pl-0"
                v-if="
                  surveySummary !== null &&
                  surveySummary !== '' &&
                  surveySummary.brands &&
                  surveySummary.brands.length > 0
                "
              >
                <li
                  v-for="(item, index) in surveySummary.brands"
                  :key="index"
                  v-show="item.IsSelected"
                >
                  {{ item.brands }}
                </li>
              </ul>
            </a-descriptions-item>

            <a-descriptions-item label="Estimate completion date">
              {{ estimateCompletionDate || "/" }}
            </a-descriptions-item>
            <a-descriptions-item label="Custom Questions" :span="2">
              <template v-if="customQuestionsList !== null">
                <ol
                  class="mb-0 pl-15"
                  v-if="customQuestionsList.CustomQuestions.length > 0"
                >
                  <li
                    v-for="(item, index) in customQuestionsList.CustomQuestions"
                    :key="index"
                  >
                    {{ item.questionText }}
                  </li>
                </ol>
                <span v-else>/</span>
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>

        <div
          class="survey-summary__details survey-summary__details--total mt-25"
        >
          <a-descriptions bordered :column="2">
            <a-descriptions-item label="Total price">
              <span class="flex items-center">
                ${{
                  formatPrice(surveySummaryPrice ? surveySummaryPrice.total : 0)
                }}
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>

      <DataInfo
        v-else
        :paddingTop="120"
        :paddingBottom="120"
        :text="'There are no data for the selected study...'"
      ></DataInfo>
    </a-modal>

    <a-modal
      title="Move study"
      :visible="modals.moveSurveyModal"
      @ok="surveyServices('MoveSurvey', 'moveSurveyModal')"
      @cancel="(modals.moveSurveyModal = false), (moveProjectID = null)"
      cancelText="Cancel"
      okText="Move"
      :okButtonProps="{ props: { disabled: moveProjectID === null } }"
      :width="360"
    >
      <p class="mb-20">Move {{ activeSurveyName }} to a different folder</p>
      <div class="input-group input-group--small">
        <a-select
          class="fullWidth"
          placeholder="Please select a folder"
          @change="selectMoveProject"
          :key="modals.moveSurveyModal"
        >
          <a-select-option
            disabled
            key="currentFolder"
            class="ant-select-dropdown-menu-item--title"
            >Current folder:
            {{ currentProject[0] ? currentProject[0].ProjectName : "" }}
          </a-select-option>
          <a-select-option
            v-for="(item, index) in projectsList"
            :key="index"
            :value="item.ProjectId"
          >
            {{ item.ProjectName }}
          </a-select-option>
        </a-select>
      </div>
      <a-spin tip="Processing..." v-if="modals.loading">
        <div class="spin-content"></div>
      </a-spin>
    </a-modal>

    <a-modal
      :title="'Delete a ' + activeSurveyName + ' study'"
      :visible="modals.deleteSurveyModal"
      @ok="surveyServices('DeleteSurvey', 'deleteSurveyModal')"
      @cancel="modals.deleteSurveyModal = false"
      cancelText="No"
      okText="Yes"
      okType="danger"
    >
      <p>Are you sure?</p>
    </a-modal>

    <a-modal
      :title="'Change log for ' + activeSurveyName + ' study'"
      :visible="modals.errorLogsModal"
      @cancel="modals.errorLogsModal = false"
      :footer="null"
      :afterClose="onCloseErrorLogModal"
      class="ant-modal--survey-error-log"
    >
      <div class="survey-summary__loader" v-if="modals.summaryLoader">
        <i class="circle-loader"></i> Loading data...
      </div>

      <a-table
        v-else
        tableLayout="auto"
        class="custom-table custom-table--survey-error-log"
        :columns="errorLogColumns"
        :row-key="(record) => record.userName + Math.random()"
        :data-source="[...errorsLogList]"
        :pagination="viewLogPagination"
        @change="viewLogTableChange"
        :expandRowByClick="true"
        :customRow="clickErrorLogRow"
      >
        <template #expandedRowRender="record, index">
          <div class="error-log-details">
            <h3>Request</h3>
            <p v-if="errorsLogList[index].errorDetails === undefined">
              Loading
            </p>
            <div
              v-show="errorsLogList[index].errorDetails !== undefined"
              :key="errorDetailsKey + Math.random()"
              class="mb-15"
            >
              {{
                errorsLogList[index].errorDetails !== undefined
                  ? errorsLogList[index].errorDetails.request
                  : "/"
              }}
            </div>
            <h3>Response</h3>
            <p v-if="errorsLogList[index].errorDetails === undefined">
              Loading
            </p>
            <div
              v-show="errorsLogList[index].errorDetails !== undefined"
              :key="errorDetailsKey + Math.random()"
            >
              {{
                errorsLogList[index].errorDetails !== undefined
                  ? errorsLogList[index].errorDetails.response
                  : "/"
              }}
            </div>

            <div
              v-if="errorsLogList[index].errorDetails !== undefined"
              class="flex error-log-details__copy"
            >
              <div
                @click="copy(index)"
                class="flex items-center cursor-pointer"
              >
                <font-awesome-icon icon="copy" />
                <span>Copy request and response</span>
                <p v-if="errorDetailsCopy" class="error-log-details__copy-done">
                  Copied
                </p>
              </div>
            </div>
          </div>
        </template>
      </a-table>

      <div class="flex justify-end mt-30">
        <a-button type="primary" @click="modals.errorLogsModal = false">
          <span>Done</span>
        </a-button>
      </div>
    </a-modal>

    <a-modal
      title="Update study status"
      :visible="modals.surveyStatus"
      :afterClose="resetCurrentSurveyStatus"
      @cancel="modals.surveyStatus = false"
      :footer="null"
      :width="404"
    >
      <p class="mb-20">
        Please select the new status below and click "Update". [Please note that
        these changes may trigger workflows in 3rd party systems.]
      </p>
      <a-row :gutter="16">
        <a-col :span="24" class="input-group input-group--small">
          <a-select
            class="fullWidth"
            option-filter-prop="children"
            placeholder="Select status"
            :key="Math.random()"
            @change="setSurveyStatus"
            :default-value="currentSurveyStatus.statusTitle || undefined"
            :class="{
              'error-field':
                currentSurveyStatus.statusId === null &&
                surveyStatusValidation.length > 0,
            }"
          >
            <a-select-option
              v-for="item in allSurveyStatus"
              :key="item.CodeId"
              :value="item.CodeId"
            >
              {{ item.CodeName }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <a-row :gutter="16" class="mt-30">
        <a-col :span="12">
          <ul
            class="validation-list validation-list--basic"
            v-if="surveyStatusValidation.length > 0"
          >
            <li v-for="(item, index) in surveyStatusValidation" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            class="flex items-center mr-10"
            @click="modals.surveyStatus = false"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveSurveyStatus"
            :disabled="
              currentSurveyStatus.prevTitle ===
                currentSurveyStatus.statusTitle ||
              currentSurveyStatus.statusId === null
            "
          >
            Submit
          </a-button></a-col
        >
      </a-row>
    </a-modal>

    <a-modal
      title="Quota List"
      :visible="activeQuotaModule !== null ? true : false"
      :afterClose="resetCurrentSurveyStatus"
      @cancel="activeQuotaModule = null"
      :footer="null"
      :width="804"
      centered
    >
      <QuotaListModal
        v-if="activeQuotaModule !== null"
        :surveyId="activeQuotaModule"
        @close-modal="activeQuotaModule = null"
      ></QuotaListModal>
    </a-modal>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";
import IconHome from "@/components/icons/IconHome.vue";
import ProductItem from "@/components/general/ProductItem.vue";
import Loader from "@/components/general/Loader.vue";
import productItem from "@/mixins/productItem.js";
import { mutations } from "@/utils/store.js";
import priceFormating from "@/mixins/priceFormating.js";
import DataInfo from "@/components/general/DataInfo.vue";
import ActiveShelf from "@/components/packTestSurvey/ActiveShelf.vue";
import SelectedProducts from "@/components/packTestSurvey/SelectedProducts.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import QuotaListModal from "@/components/general/QuotaListModal.vue";

export default {
  name: "Dashboard",
  components: {
    IconBase,
    IconHome,
    ProductItem,
    Loader,
    IconNoResults,
    DataInfo,
    ActiveShelf,
    SelectedProducts,
    IconSearchThin,
    QuotaListModal,
  },
  mixins: [productItem, priceFormating],
  data() {
    return {
      user: null,
      client: null,
      loading: false,
      launchpad: [
        {
          id: 1,
          typeID: null,
          title: "Brand Operating System",
          viewRouteName: "brandOperatingSystem",
          disableCreate: false,
          createBtnLabel: "Demo",
          disableView: false,
          description:
            "This ML algorithm driven system tracks actual behavioral shifts, analyzes actual engagement with your brand in a 3D virtual store encompassing over 20 categories, gauges your brand's distinction and standing compared to category competitors, and employs its specialized algorithm to identify market opportunities with 80% predictive accuracy on purchasing behavior.",
        },
        {
          id: 2,
          typeID: 93,
          title: "Pack Test",
          viewRouteName: null,
          description:
            "Evaluate the First Moment of Truth performance of your new package communications using the Hotspex 3D virtual store environment. Choose from diverse categories and settings, upload your new pack designs, and analyze consumer shopping behaviors, pack attention-grabbing ability, navigability, and consumer sentiment in detail.",
        },
        {
          id: 3,
          typeID: 97,
          viewRouteName: null,
          title: "In-Situ Comms Test",
          description:
            "Evaluate your ad's effectiveness at engaging, conveying brand and product messaging, and enhancing purchase likelihood by using a realistic YouTube environment. This platform enables you to gather data on ad skip rates, viewing duration, unbiased brand and category recall, and the ad's influence on brand salience, perceptions, and purchase preferences.",
        },
        {
          id: 4,
          typeID: 102,
          title: "Media Emotional Coherence Test",
          viewRouteName: null,
          description:
            "Refine your advertising strategy and maximize brand impact by assessing the alignment of your ad's emotional intent with the corresponding YouTube placements. With the Media Emotional Coherence Test, you unlock data-driven evaluations on emotional congruence, which enhances ad reception and supports strategic ad placements, ultimately boosting purchase intent and brand affinity.",
        },
        {
          id: 5,
          typeID: null,
          title: "Media Alignment",
          viewRouteName: null,
          viewURL: "https://www.hotspexmedia.com/services/digital-advertising",
          createURL: "https://www.hotspexmedia.com/contact",
          createRouteName: null,
          disableCreate: false,
          createBtnLabel: "Demo",
          description:
            "Reticle™ is a contextual data provider that helps advertisers align the emotional intent of their ads with placements that convey a similar emotional tone. This alignment enhances the likelihood of ads being perceived more favorably and, consequently, drives incremental brand growth.",
        },
        {
          id: 6,
          typeID: null,
          title: "Shelf Builder",
          viewRouteName: "shelf-builder-library",
          createRouteName: "shelf-builder-library",
          description:
            "Use either PSA or CSV to create and visualize your planograms. Easily drag and drop products on shelf, update pricing and information, and update pack designs to the download the image you want to test.",
        },

        {
          id: 7,
          typeID: null,
          title: "Concept Test",
          viewRouteName: null,
          disableCreate: true,
          disableView: true,
          description:
            "This comprehensive test provides you with critical insights into consumer acceptance, perceived value, and the unique selling proposition of your product concepts. It measures initial reaction, purchase intent, affective heuristics, messaging and unconscious associations with target communication objectives. Use our detailed feedback analysis to refine your product offerings, tailor your marketing strategies, and ensure that your product launch resonates well with consumers, driving both engagement and sales.",
        },
      ],
      folders: [],
      foldersModal: false,
      loadingFolders: true,
      activeFolderId: null,
      activeProduct: null,
      contact: {
        name: "",
        company: "",
        message: "",
      },
      contactModal: false,
      sendContactLoading: false,
      activeProjects: [],
      searchParameters: "",
      searchActive: false,
      loadingPinProjectID: null,
    };
  },
  computed: {
    groupedProducts() {
      let filteredProjects = this.activeProjects;

      if (this.searchParameters.length >= 3) {
        filteredProjects = filteredProjects.filter((project) => {
          return this.searchParameters
            .toLowerCase()
            .split(" ")
            .every((term) => project.SurveyName.toLowerCase().includes(term));
        });
      }

      filteredProjects.sort((a, b) => {
        if (b.Pinned === a.Pinned) {
          return a.SurveyName.toLowerCase().localeCompare(
            b.SurveyName.toLowerCase()
          );
        }
        return b.Pinned - a.Pinned;
      });

      let grouped = [];
      for (let i = 0; i < filteredProjects.length; i += 4) {
        grouped.push(filteredProjects.slice(i, i + 4));
      }
      return grouped;
    },
  },

  methods: {
    initProject(project) {
      this.activeProduct = project;
      if (
        project.typeID === 97 ||
        project.typeID === 93 ||
        project.typeID === 102
      ) {
        this.getFolders();
        this.foldersModal = true;
      } else if (project.createRouteName !== null) {
        this.$router.push({
          name: project.createRouteName,
        });
      } else if (project.createURL !== null) {
        window.open(project.createURL, "_blank", "noreferrer");
      }
    },
    createProject() {
      if (
        this.activeProduct.typeID === 97 ||
        this.activeProduct.typeID === 102
      ) {
        this.createVideoProject();
      } else {
        this.createPackTestProject();
      }
    },
    getFolders() {
      const getFolders = new Promise((resolve) => {
        wsUtils.GetProject(
          { clientID: this.client.ClientId, userID: this.user.EncrypteduserID },
          resolve
        );
      });

      getFolders.then((data) => {
        this.folders = data;
        this.loadingFolders = false;
      });
    },
    selectFolder(id) {
      this.activeFolderId === id
        ? (this.activeFolderId = null)
        : (this.activeFolderId = id);
    },
    createVideoProject() {
      let routeName;
      switch (this.activeProduct.typeID) {
        case 97:
          routeName = "comms-setting";
          break;
        case 102:
          routeName = "vid-reticle-setting";
          break;
        default:
          routeName = "vid-setting";
          break;
      }

      mutations.setSurveyPrice(this.activeProduct.typeID, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyData1(
          {
            SurveyTypeId: this.activeProduct.typeID,
            ProjectID: Number(this.activeFolderId),
            UserID: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        this.foldersModal = false;
        let surveyPriceSetData = {
          SurveyId: data.SurveyId,
          SurveyType: this.activeProduct.typeID,
          ClickSpex: true,
          Stims: [{ duration: 100 }],
          Question: [],
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };

        if (this.activeProduct.typeID === 97) {
          surveyPriceSetData.ReportType = "Automated";
        } else {
          surveyPriceSetData.ReportType = "Base";
        }

        jsUtils.setSurveyTypeId(this.activeProduct.typeID);
        jsUtils.setSurveyPrice(surveyPriceSetData);
        jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
        jsUtils.setRunSurveyMode(true);

        mutations.setSurveyPrice(data.SurveyId, "SurveyId");

        this.$router.push({
          name: routeName,
          params: { id: data.SurveyId },
        });
      });
    },
    createPackTestProject() {
      mutations.setSurveyPrice(this.activeProduct.typeID, "SurveyType");
      const createSurvey = new Promise((resolve) => {
        wsUtils.InsertSurveyPacktest(
          {
            SurveyTypeId: this.activeProduct.typeID,
            ProjectID: Number(this.activeFolderId),
            UserID: this.user.EncrypteduserID,
          },
          resolve
        );
      });
      createSurvey.then((data) => {
        this.foldersModal = false;
        let surveyPriceSetData = {
          SurveyId: data.SurveyId,
          SurveyType: this.activeProduct.typeID,
          ClickSpex: true,
          Stims: [],
          Question: [],
          ReportOption: "Automated",
          Audience: {
            sampleSize: 200,
            categoryUser: 0,
            genPop: 1,
          },
        };

        let packTestPriceSetData = {
          surveyId: data.SurveyId,
          explicitSamplePerDeepDive: 100,
          legs: 1,
          ReportOption: "Automated",
          implicitComponent: true,
          deepDive: 1,
          compDeepDive: 0,
          comObjects: 1,
        };

        jsUtils.setSurveyTypeId(this.activeProduct.typeID);
        jsUtils.setSurveyPrice(surveyPriceSetData);
        jsUtils.setSurveyUnEncryptId(data.UnEncryptSId);
        jsUtils.setRunSurveyMode(true);
        jsUtils.setPackTestPrice(packTestPriceSetData);

        mutations.setSurveyPrice(data.SurveyId, "SurveyId");

        this.$router.push({
          name: "pack-test-setting",
          params: { id: data.SurveyId },
        });
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
      });
    },
    sendContact() {
      this.sendContactLoading = true;
      const data = {
        wsName: "Contactform",
        data: {
          UserId: this.user.EncrypteduserID,
          Name: this.contact.name,
          CompanyName: this.contact.company,
          Description: this.contact.message,
        },
      };
      const send = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      send.then((data) => {
        this.sendContactLoading = false;
        this.contactModal = false;
        if (data.success) {
          this.$notification["success"]({
            message: "Well done!",
            description:
              "Your message has been sent successfully. We will contact you soon.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.contact.name = "";
          this.contact.company = "";
          this.contact.message = "";
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    viewProjects(project) {
      if (
        project.typeID === 97 ||
        project.typeID === 93 ||
        project.typeID === 102
      ) {
        jsUtils.setSurveysApiTrigger(true);
        mutations.setDefaultSurveyType(project.typeID);
        this.$router.push({
          path: "all-projects",
        });
      } else if (project.viewRouteName !== null) {
        this.$router.push({
          name: project.viewRouteName,
        });
      } else if (project.viewURL !== null) {
        window.open(project.viewURL, "_blank", "noreferrer");
      }
    },
    pinProject(project) {
      this.loadingPinProjectID = project.SurveyID;
      const data = {
        wsName: "PinSurveys",
        data: {
          Pinned: project.Pinned ? false : true,
          SurveyId: project.SurveyID,
        },
      };

      const pinProject = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      pinProject.then((data) => {
        this.loadingPinProjectID = null;
        if (data.Success) {
          this.getActiveProjects();
          this.$notification["success"]({
            message: "Well done!",
            description: `Project ${
              project.Pinned ? "unpinned" : "pinned"
            } successfully.`,
            placement: "bottomLeft",
            duration: 4,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
  },
  activated() {
    jsUtils.clearSurveyPrice();
    jsUtils.clearPackTestPrice();
  },
  created() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.getActiveProjects();
    this.contact.name = this.user.firstName;
    if (!this.user.BOSModulesEnabled) {
      this.launchpad.find((project) => project.id === 1).disableView = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  min-height: 100vh;
  padding-bottom: 30px;
  ::v-deep .ant-carousel {
    .slick-dots li button {
      background: var(--red);
      height: 5px;
    }
    .slick-dots-bottom {
      bottom: -20px;
    }
  }
  &__loader {
    padding: 100px 0;
  }
  .page-title {
    display: flex;
    gap: 9px;
    margin-bottom: 57px;
    ::v-deep svg {
      path {
        stroke-width: 3px;
        fill: transparent;
      }
    }
  }
  .section-title {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.active-projects {
  margin-bottom: 30px;
  padding-bottom: 64px;
  &.no-projects {
    padding-bottom: 30px;
  }
  &__info {
    padding: 0px 0 20px;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__carousel {
    position: relative;

    ::v-deep .slick-prev,
    ::v-deep .slick-next {
      width: 40px;
      height: 40px;
      background: white;
      z-index: 99;
      border: 1px solid #e5e5e5;
      color: var(--darkText);
      display: flex !important;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease transform;
      border-radius: 4px;
      &:before {
        font-size: 25px;
      }
    }
    ::v-deep .slick-prev {
      left: -20px;
      &:hover {
        transform: translateX(-2px);
      }
    }
    ::v-deep .slick-next {
      right: -20px;
      &:hover {
        transform: translateX(2px);
      }
    }
  }
  &__carousel-items-wrapper {
    display: flex;
    overflow: hidden;
    gap: 1.5%;
    .product-item {
      height: auto;
      width: 23.8%;
      min-width: 23.8%;
    }
  }
}

.products {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px 25px;
  }
  &__item {
    background: linear-gradient(333deg, #f4f4f4 0%, #fdfdfd 45%);
    border-radius: 20px;
    padding: 18px 20px 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(333deg, #4d398f 0%, #5b4894 45%);
      opacity: 0;
      transition: 0.3s ease opacity;
    }
    i {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: #e2e2e2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 13px;
      display: none;
      &.color1 {
        background: #ecebff;
      }
      &.color2 {
        background: #eaf5ff;
      }
      &.color3 {
        background: #fff6ee;
      }
      &.color4 {
        background: #e9f8f5;
      }
    }
    h3 {
      font-size: 21px;
      line-height: 30px;
      margin-bottom: 10px;
      letter-spacing: 0.5px;
      font-weight: 600;
      transition: 0.3s ease color;
    }
    p {
      font-size: 14px;
      color: #131313;
      margin-bottom: 27px;
      flex: 1;
      line-height: 22px;
      transition: 0.3s ease color;
    }
    .ant-btn:not(.ant-btn-link) {
      min-width: 70px;
      height: 35px;
      padding: 0 12px;
      font-size: 14px;
    }
    &:hover {
      h3,
      p {
        color: #fff;
        position: relative;
      }
      &:before {
        opacity: 1;
      }
      .products__item-number {
        opacity: 0.05;
        color: #fff;
      }
      .ant-btn.ant-btn-link {
        color: #fff !important;
      }
    }
  }
  &__item-number {
    font-style: normal;
    font-weight: 900;
    font-size: 170px;
    color: #4e29c2;
    display: block;
    opacity: 0.04;
    line-height: 71px;
    margin: -5px 0 -14px -31px;
    pointer-events: none;
    transition: 0.3s ease all;
    &.color1 {
      color: #4a42d9;
    }
    &.color2 {
      color: #1e6bb1;
    }
    &.color3 {
      color: #d76500;
    }
    &.color4 {
      color: #02866c;
    }
    &.color5 {
      color: #8a8913;
    }
    &.color6 {
      color: #4a4a4a;
    }
  }
}

.contact {
  padding: 60px 24px;
  &__info {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    span {
      background: #ffefb7;
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 16px;
      color: #c45c02;
      margin-bottom: 10px;
      display: inline-flex;
    }
    h3 {
      font-size: 38px;
      font-weight: 600;
      line-height: 47px;
      margin-bottom: 30px;
      max-width: 800px;
      margin: 0 auto 30px;
    }
  }
}

.send-contact-loader {
  position: absolute;
  left: 0;
  top: 54px;
  background: rgba(255, 255, 255, 0.8);
  bottom: 61px;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.folders {
  margin: 0;
  padding: 0;
  li {
    padding: 13px 14px;
    font-size: 14px;
    background: linear-gradient(128deg, #f3f3f3 0%, #fbfafa 100%);
    margin-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid transparent;
    display: flex;
    &:hover,
    &.is-active {
      color: var(--blue);
    }
    i {
      width: 20px;
      display: block;
    }
    svg {
      margin-right: 2px;
    }
  }
}

.ant-modal {
  .ant-spin-nested-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.85);
  }
}
</style>
