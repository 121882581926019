<template>
  <div class="active-shelf-tools">
    <div class="flex items-center active-shelf-tools__slider">
      <span>Zoom Out</span>
      <a-slider
        v-model="localSizeDivider"
        :min="1"
        :max="defaultSizeDivider"
        :step="0.001"
        :tooltipVisible="false"
        :reverse="true"
      />
      <span>Zoom In</span>
    </div>
    <div class="flex gap-10">
      <a-dropdown :trigger="['click']">
        <div class="active-shelf-tools__color" @click.prevent>
          <label>Background color</label
          ><i
            :style="{
              backgroundColor: color,
            }"
          ></i>
        </div>
        <template #overlay>
          <color-picker v-model="color"></color-picker>
        </template>
      </a-dropdown>
      <div
        class="active-shelf-tools__deep-dive"
        :disabled="!hasDeepDiveProduct"
      >
        <label>Show deep dive products</label>
        <a-switch v-model="inDeepDiveProducts" />
      </div>
      <a-dropdown :trigger="['click']">
        <a-button type="dark" class="small-ant-dropdown-trigger" @click.prevent
          ><font-awesome-icon icon="ellipsis-h" />
        </a-button>
        <template #overlay>
          <a-menu class="dark-dropdown-menu">
            <a-menu-item key="0">
              <a @click="uploadBulkImages">Upload product images</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a @click="addNewProduct">Add new product</a>
            </a-menu-item>
            <a-menu-item key="2" v-if="activeShelfIndex !== 0">
              <a @click="deleteShelf"> Delete Test {{ activeShelfIndex }}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-button
        type="dark"
        @click="$emit('create-shelf-image')"
        class="ant-btn--confirm"
        :disable="disablePreviewShelfImg"
        ><font-awesome-icon icon="eye" />
        <span v-if="activeShelfIndex === 0">Preview Control</span>
        <span v-else>Preview Test {{ activeShelfIndex }}</span>
      </a-button>
    </div>
  </div>
</template>

<script>
import { Chrome } from "vue-color";

export default {
  name: "ActiveShelfTools",
  components: {
    "color-picker": Chrome,
  },
  props: {
    loading: {
      type: Boolean,
    },
    sizeDivider: {
      type: Number,
      default() {
        return 1;
      },
    },
    activeShelfIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    defaultSizeDivider: {
      type: Number,
      default() {
        return 1;
      },
    },
    disablePreviewShelfImg: {
      type: Boolean,
    },
    showInDeepDiveProducts: {
      type: Boolean,
    },
    hasDeepDiveProduct: {
      type: Boolean,
    },
    shelfColor: {
      type: [Object, String],
    },
  },
  data() {
    return {};
  },
  computed: {
    localSizeDivider: {
      get() {
        return this.sizeDivider;
      },
      set(newValue) {
        this.$emit("update-size-divider", newValue);
      },
    },
    inDeepDiveProducts: {
      get() {
        return this.showInDeepDiveProducts;
      },
      set(newValue) {
        this.$emit("show-in-deep-dive", newValue);
      },
    },
    color: {
      get() {
        return this.shelfColor;
      },
      set(newValue) {
        this.$emit("set-color", newValue.hex);
      },
    },
  },
  watch: {},
  methods: {
    deleteShelf() {
      let _this = this;
      this.$confirm({
        title: `Delete Test ${_this.activeShelfIndex}`,
        class: "ant-modal--dark",
        content: () => (
          <div>
            Are you sure you want to delete Test {_this.activeShelfIndex}?
          </div>
        ),
        onOk() {
          _this.$emit("delete-shelf", _this.activeShelfIndex);
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    uploadBulkImages() {
      this.$emit("upload-bulk-images", {
        fileType: ".zip",
        fileLabel: "ZIP",
      });
    },
    addNewProduct() {
      this.$emit("add-new-product");
    },
  },
};
</script>

<style lang="scss" scoped>
.active-shelf-tools {
  display: flex;
  padding: 0 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  z-index: 99;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .ant-btn {
    &--confirm {
      min-width: 155px;
    }
    svg {
      margin-right: 8px;
    }
    &[disable] {
      opacity: 0.5;
      cursor: default;
      background: #1890ff !important;
    }
    &.ant-btn-dark {
      background: #3c3d3e;
      border-color: #4c4d4e;
      &:hover {
        background: #404141;
      }
    }
  }

  &__color {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    label {
      color: white;
      font-size: 13px;
      margin-right: 6px;
    }
    i {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: block;
      border: 3px solid #3e3f40;
    }
  }

  &__deep-dive {
    display: flex;
    align-items: center;
    margin-right: 20px;
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
    label {
      color: white;
      font-size: 13px;
      margin-right: 6px;
    }
    ::v-deep .ant-switch {
      background: #373839;
      border: 1px solid #4c4d4e;
      &.ant-switch-checked {
        background: var(--blue);
      }
    }
  }

  &__slider {
    color: #d9d9d9;
    font-size: 13px;
    gap: 10px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: 15px;
    background: rgba(26, 27, 29, 0.9);
    padding: 0 11px;
    border-radius: 8px;

    ::v-deep .ant-slider {
      width: 140px;
      margin-right: 0;
      .ant-slider-rail {
        height: 2px;
        background: var(--blue);
      }
      .ant-slider-track {
        height: 2px;
        background-color: #464646;
      }
      .ant-slider-step {
        height: 2px;
      }

      .ant-slider-handle {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: solid 2px #afafaf;
      }
    }
  }
}
</style>
