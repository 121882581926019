var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-item",class:{ 'product-item--grey': _vm.theme === 'grey' }},[_c('a-tooltip',{attrs:{"placement":"top","overlayClassName":"ant-tooltip--step-nav"}},[_c('template',{slot:"title"},[(!_vm.product.Pinned)?_c('span',[_vm._v("Pin this project")]):_c('span',[_vm._v("Unpin this project")])]),(_vm.showBookmarkBtn)?_c('div',{staticClass:"product-item__bookmark",class:{ 'product-item__bookmark--pinned': _vm.product.Pinned },on:{"click":function($event){return _vm.$emit('pin-project', _vm.product)}}},[(_vm.loadingPinProject)?_c('Loader',{staticClass:"space-0"}):_c('font-awesome-icon',{attrs:{"icon":"star"}})],1):_vm._e()],2),_c('a-dropdown',{attrs:{"trigger":['click']},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('a-menu',[(_vm.product.SurveyStatus === 'Complete')?_c('a-menu-item',{key:"0"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('view-report', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"file"}})],1),_vm._v(" View Report ")])],1):_vm._e(),(_vm.product.SurveyStatus !== 'Draft')?_c('a-menu-item',{key:"1"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('get-summary', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"poll-h"}})],1),_vm._v(" Summary ")])],1):_vm._e(),(_vm.product.SurveyStatus === 'Draft')?_c('a-menu-item',{key:"2"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('edit', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1),_vm._v(" Edit ")])],1):_vm._e(),(
            ['Queued', 'Report Preparation', 'In Field'].includes(
              _vm.product.SurveyStatus
            )
          )?_c('a-menu-item',{key:"3"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('define-report', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"file"}})],1),_vm._v(" Define Report ")])],1):_vm._e(),(
            (_vm.product.SurveyStatus === 'Report Preparation' ||
              _vm.product.SurveyStatus === 'Report Uploaded') &&
            _vm.user.AccountType === 'Master/Hotspex'
          )?_c('a-menu-item',{key:"4"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('open-upload-report-modal', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper ant-btn-icon-wrapper--upload-report"},[_c('font-awesome-icon',{attrs:{"icon":"file-upload"}})],1),_vm._v(" Upload Report ")])],1):_vm._e(),(
            (_vm.product.SurveyStatus === 'Report Preparation' ||
              _vm.product.SurveyStatus === 'Report Uploaded') &&
            _vm.user.AccountType === 'Master/Hotspex' &&
            _vm.user.SkinIdentifier === 'pg'
          )?_c('a-menu-item',{key:"5"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('open-upload-raw-data-modal', _vm.product)}}},[_c('span',{staticClass:"\n                ant-btn-icon-wrapper ant-btn-icon-wrapper--upload-raw-report\n              "},[_c('i')]),_vm._v(" Upload Raw data ")])],1):_vm._e(),(
            _vm.product.SurveyStatus === 'Report Preparation' ||
            _vm.product.SurveyStatus === 'Complete'
          )?_c('a-menu-item',{key:"6"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('download-data', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"download"}})],1),_vm._v(" Survey data ")])],1):_vm._e(),_c('a-menu-item',{key:"7"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('show-survey-services-modal', {
                surveyID: _vm.product.SurveyID,
                surveyName: _vm.product.SurveyName,
                modalType: 'moveSurveyModal',
              })}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"expand-arrows-alt"}})],1),_vm._v(" Move ")])],1),(!_vm.hideCopyBtn)?_c('a-menu-item',{key:"8"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('show-survey-clone-modal', {
                surveyID: _vm.product.SurveyID,
                surveyName: _vm.product.SurveyName,
                modalType: 'cloneSurveyModal',
              })}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"copy"}})],1),_vm._v(" Copy ")])],1):_vm._e(),(_vm.product.SurveyStatus !== 'Queued')?_c('a-menu-item',{key:"9"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('delete-survey', {
                surveyID: _vm.product.SurveyID,
                surveyName: _vm.product.SurveyName,
              })}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1),_vm._v(" Delete ")])],1):_vm._e(),(_vm.user.planID === 4)?_c('a-menu-item',{key:"10"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('view-log', {
                surveyID: _vm.product.SurveyID,
                surveyName: _vm.product.SurveyName,
              })}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{staticStyle:{"width":"14px"},attrs:{"icon":"eye"}})],1),_vm._v(" View Log ")])],1):_vm._e(),(
            _vm.product.SurveyStatus !== 'Draft' &&
            _vm.product.SurveyTypeName === 'Pack Test'
          )?_c('a-menu-item',{key:"11"},[_c('a-button',{staticClass:"flex items-center",attrs:{"type":"link"},on:{"click":function($event){return _vm.$emit('add-leg', _vm.product)}}},[_c('span',{staticClass:"ant-btn-icon-wrapper"},[_c('font-awesome-icon',{staticStyle:{"width":"14px"},attrs:{"icon":"plus"}})],1),_vm._v(" Add a Leg ")])],1):_vm._e()],1)]},proxy:true}])},[_c('a',{staticClass:"product-item__dropdown-btn",on:{"click":function($event){$event.preventDefault();}}},[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})],1)]),_c('div',{staticClass:"product-item__flag",style:(_vm.statusStyle)},[_vm._v(" "+_vm._s(_vm.product.SurveyStatus)+" "),(_vm.user.AccountType.toLowerCase() === 'master/hotspex')?_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":"pencil-alt"},on:{"click":function($event){return _vm.$emit('edit-status', _vm.product)}}}):_vm._e()],1),_c('div',{staticClass:"product-item__inner",on:{"click":function($event){return _vm.$emit('click', _vm.product)}}},[_c('div',{staticClass:"product-item__image",class:{
        'product-item__image--contain':
          _vm.product.SurveyTypeName.toLowerCase() === 'pack test',
      }},[_c('div',{staticClass:"product-item__action"},[_c('span',[_vm._v(_vm._s(_vm.rowCaptionContent))])]),(_vm.product.imageUrl)?_c('img',{attrs:{"src":_vm.product.imageUrl,"alt":""}}):(_vm.product.SurveyTypeName.toLowerCase() === 'pack test')?_c('icon-base',{attrs:{"width":64,"height":64,"viewBox1":64,"viewBox2":64,"iconName":"packtest","iconColor":"#9e9e9e","iconStroke":"#9e9e9e"}},[_c('icon-packtest')],1):_c('icon-base',{attrs:{"width":74,"height":74,"viewBox1":64,"viewBox2":64,"iconName":"packvideotest","iconColor":"#9e9e9e","iconStroke":"#9e9e9e"}},[_c('icon-videotest')],1)],1),_c('div',{staticClass:"product-item__info"},[_c('h3',[_vm._v(_vm._s(_vm.product.SurveyTypeName))]),_c('div',{staticClass:"flex-1"},[_c('h2',[_vm._v(_vm._s(_vm.product.SurveyName || "/"))]),_c('div',{staticClass:"product-item__info-tags"},[(_vm.product.CountryName)?_c('span',[_vm._v(_vm._s(_vm.product.CountryName))]):_vm._e(),(_vm.product.Retailer)?_c('span',[_vm._v(_vm._s(_vm.product.Retailer))]):_vm._e(),(_vm.product.category)?_c('span',[_vm._v(_vm._s(_vm.product.category))]):_vm._e(),(_vm.product.creativeStage)?_c('span',[_vm._v(_vm._s(_vm.product.creativeStage))]):_vm._e()])]),_c('div',{staticClass:"product-item__info-author"},[_c('i',[_c('icon-base',{attrs:{"width":35,"height":36,"viewBox1":24,"viewBox2":24,"iconName":"user","iconColor":"transparent","iconStroke":"#7e7e7e"}},[_c('icon-user')],1)],1),_c('div',[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.product.LaunchedBy))])]),_c('span',[_vm._v(_vm._s(_vm.product.Date_Created))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }