<template>
  <div
    class="shelf-builder"
    :class="{
      'p-20': shelf === null,
      'shelf-builder--hide-price': !showPrice,
      'shelf-builder--overflow-h': uploadProcessLoading.find(
        (item) => item === shelf.shelftype
      ),
    }"
  >
    <div
      class="shelf-builder__inner-loading"
      v-if="uploadProcessLoading.find((item) => item === shelf.shelftype)"
    >
      <Loader
        text="Uploadig PSA... Please Wait. This might take a while."
      ></Loader>
    </div>
    <div class="shelf-builder__loading text-center" v-if="loading">
      <div class="products-loader">
        <ul class="products-loader__list">
          <li class="products-loader__list-item first"></li>
          <li class="products-loader__list-item second"></li>
          <li class="products-loader__list-item third"></li>
          <li class="products-loader__list-item fourth"></li>
          <li class="products-loader__list-item fifth"></li>
          <li class="products-loader__list-item sixth"></li>
        </ul>
        <div class="products-loader__shelf"></div>
      </div>
      <Loader :text="loadingMessage"></Loader>
    </div>
    <Tools
      :class="{
        disabled: uploadProcessLoading.length > 0,
      }"
      v-if="shelf"
      :loading="loading"
      :saveDataLoading="saveDataLoading"
      :projectName="projectName"
      :activeShelf="shelf.shelftype"
      :allShelfLength="allShelves.length"
      @open-upload-modal="openUploadModal"
      @save="checkSaveConditions"
      @upload="getGCSProjects"
      @download-csv="downloadCsv"
      @download-csv-all="downloadCsv('all')"
      @change-currency="changeCurrencyModal = true"
    ></Tools>

    <div class="shelf-builder__active-tools">
      <Tabs
        v-if="shelf"
        :allShelvesLength="allShelves.length"
        :activeShelfIndex="activeShelfIndex"
        :allShelvesUrlCheck="allShelvesUrlCheck"
        @active-shelf-index="setActiveShelf($event)"
        @add-test="addTest"
      ></Tabs>

      <ActiveShelfTools
        v-if="shelf"
        :class="{
          disabled: uploadProcessLoading.find(
            (item) => item === shelf.shelftype
          ),
        }"
        :loading="loading"
        :defaultSizeDivider="defaultSizeDivider"
        :sizeDivider="sizeDivider"
        :activeShelfIndex="activeShelfIndex"
        :disablePreviewShelfImg="shelf === null"
        :showInDeepDiveProducts="showInDeepDiveProducts"
        :hasDeepDiveProduct="hasDeepDiveProduct"
        :shelfColor="shelf.bcgcolor"
        @create-shelf-image="previewShelfImage"
        @update-size-divider="sizeDivider = $event"
        @upload-bulk-images="openUploadModal"
        @add-new-product="(addNewProductModal = true), findUniqueBrandNames()"
        @delete-shelf="deleteShelf"
        @show-in-deep-dive="showInDeepDiveProducts = $event"
        @set-color="(shelf.bcgcolor = $event), (currentShelfBcgColor = $event)"
      ></ActiveShelfTools>
    </div>

    <Upload
      v-if="!shelf"
      @csv-uploaded="setCsvData"
      @get-project-details="getProjectDetails"
      :shelfId="shelfId"
      :userId="user ? user.EncrypteduserID : ''"
    ></Upload>

    <div
      class="shelf-builder__inner"
      v-else
      ref="shelfBuilderInner"
      :style="{
        width:
          shelf.mainShelfWidth / sizeDivider +
          (shelfWrapperBorderWidth / sizeDivider) * 2 +
          'px',
        'border-width': `${shelfWrapperBorderWidth / sizeDivider}px`,
      }"
    >
      <div
        class="shelf-builder__inner-col"
        :class="{
          'shelf-builder__inner-col--no-reverse':
            shelf.dataSource === 'CSV' || shelf.dataSource === '',
        }"
        v-for="(column, colIndex) in shelf.bays"
        :key="colIndex"
        :style="{
          minWidth: `${column.bayWidth / sizeDivider}px`,
          backgroundColor: currentShelfBcgColor,
        }"
        :data-bay-index="colIndex"
      >
        <div v-for="(rowInCol, rowIndex) in column.shelves" :key="rowIndex">
          <draggable
            :list="rowInCol.products"
            handle=".js-draggable-handle"
            :group="{ name: 'row', pull: true, put: true }"
            :tag="'div'"
            class="shelf-builder__row"
            selected-class="deki"
            :style="{
              minHeight: `${rowInCol.shelfHeight / sizeDivider}px`,
              'padding-bottom': `${shelfPaddingBottom / sizeDivider}px`,
              'background-size': `auto ${shelfPaddingBottom / sizeDivider}px`,
              gap: `${
                rowInCol.emptyWidth / rowInCol.noOfProducts / sizeDivider
              }px`,
            }"
            @end="onEndDragging"
            @start="onStartDragging"
            :multi-drag="true"
            :avoid-implicit-deselect="true"
            :data-row-index="rowIndex"
          >
            <div
              class="shelf-builder__item"
              v-for="(product, index) in rowInCol.products"
              :key="product.upc"
              :style="itemStyles[colIndex][rowIndex][index]"
              :data-upc="product.upc"
            >
              <nested-draggable
                :items="product.stack"
                :sizeDivider="sizeDivider"
                :timestamp="timestamp"
                :shelfPaddingBottom="shelfPaddingBottom"
                :currencySymbol="shelf.currencySymbol"
                :selectedProduct="selectedProduct"
                :loading="loading"
                :showInDeepDiveProducts="showInDeepDiveProducts"
                :startDragging="startDragging"
                :hidePrice="!showPrice"
                @init-resize="initResize($event)"
                @item-clicked="onItemClicked($event)"
                @start-dragging="startDragging = true"
                @end-dragging="startDragging = false"
                @product-stacked="onProductStacked($event)"
                @calculate-shelf-layout="calculateShelfLayoutAndDimensions"
                ref="nestedDraggableRef"
              />
              <div
                class="position-r shelf-builder__item-content"
                :ref="`productRef_${product.upc}`"
                :class="{
                  'is-active':
                    selectedProduct &&
                    product.upc === selectedProduct.upc &&
                    !loading,
                  'is-deep-dive': showInDeepDiveProducts && product.inDeepDive,
                  'no-image':
                    !product.thumbProductURL &&
                    !product.thumb_rotated_image_url,
                  'multi-select': isMultiSelected(product) && !loading,
                }"
              >
                <div
                  class="shelf-builder__item-resize"
                  v-if="selectedProduct && selectedProduct.upc === product.upc"
                  :style="{
                    right: `${-4 / sizeDivider}px`,
                    top: `${-11 / sizeDivider}px`,
                    borderTopWidth: `${18 / sizeDivider}px`,
                    borderBottomWidth: `${21 / sizeDivider}px`,
                    borderLeftWidth: `${20 / sizeDivider}px`,
                  }"
                  @mousedown="initResize"
                ></div>
                <div
                  @click.stop="onItemClicked(product, $event, 'main-product')"
                  class="shelf-builder__item-image js-draggable-handle"
                  ref="itemImage"
                >
                  <div
                    class="shelf-builder__item-image-row"
                    v-for="(imgRow, imgRowIndex) in product.heightFacing"
                    :key="imgRowIndex"
                  >
                    <img
                      v-for="(img, imgIndex) in product.widthFacing"
                      :key="imgIndex"
                      :src="`${
                        product.thumb_rotated_image_url ||
                        product.thumbProductURL
                      }?time=${timestamp}`"
                      :width="product.productWidth / sizeDivider"
                      :height="product.productHeight / sizeDivider"
                      v-show="
                        product.thumbProductURL ||
                        product.thumb_rotated_image_url
                      "
                      crossOrigin="anonymous"
                    />
                  </div>
                </div>

                <div
                  class="shelf-builder__item-price"
                  :style="{
                    fontSize: `${13 / sizeDivider}px`,
                    bottom: `-${(shelfPaddingBottom / 2 + 12) / sizeDivider}px`,
                  }"
                  v-if="
                    product.showPrice && product.price !== 0 && product.price
                  "
                >
                  {{ shelf.currencySymbol || "" }}{{ product.price }}
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>

    <Popover
      v-if="selectedProduct && productPopoverVisible === selectedProduct.upc"
      :selectedProductFacingWidth="selectedProductFacingWidth"
      :selectedProductFacingHeight="selectedProductFacingHeight"
      :selectedProduct="selectedProduct"
      @reset-product-popover-visible="
        (productPopoverVisible = null),
          (selectedProduct = null),
          (cloneSelectedProduct = null)
      "
      @show-product-preview="productPreviewModal = true"
      :copiedSizeProductUPC="copiedSizeProductUPC"
      :inDeepDive="selectedProduct.inDeepDive"
      @update-product-facing="updateProductFacing"
      @copy-product-size="copyProductSize"
      @update-facing-width="selectedProductFacingWidth = $event"
      @update-facing-height="selectedProductFacingHeight = $event"
      @update-deep-dive="setDeepDiveProduct"
      @reset-size-copied="resetSizeCopied"
    ></Popover>

    <a-modal
      title="Product details"
      :visible="productPreviewModal"
      :footer="null"
      :width="1080"
      @cancel="updateUPCAllTests"
      v-if="selectedProduct"
      class="ant-modal--dark"
      centered
    >
      <ProductPreview
        v-if="selectedProduct && productPreviewModal"
        :uploadProductImagesLoading="uploadProductImagesLoading"
        @upload-product-images="uploadProductImages"
        @delete-product-by-upc-confirm="deleteProductByUpcConfirm"
        @close-product-preview="updateUPCAllTests"
        @calculate-natural-img-size="calculateNaturalImgSize"
        @calculate-new-positions="calculateShelfLayoutAndDimensions"
        @update-product-width="selectedProduct.productWidth = $event"
        @update-product-height="selectedProduct.productHeight = $event"
        @update-product-name="selectedProduct.productName = $event"
        @update-product-price="selectedProduct.price = $event"
        @update-product-weight="selectedProduct.weight = $event"
        @update-product-image="updateProductImageRotation"
        :selectedProduct="selectedProduct"
        :surveyID="this.shelfId"
        :shelfType="this.shelf.shelftype"
      ></ProductPreview>
    </a-modal>

    <a-modal
      :title="`Upload ${activeUpload.typeLabel} file`"
      :visible="uploadModal"
      :footer="null"
      :width="520"
      @cancel="uploadModal = false"
      centered
    >
      <a-upload-dragger
        name="file"
        :accept="activeUpload.accept"
        :multiple="false"
        :showUploadList="false"
        :customRequest="dynamicUpload"
        ref="upload"
        class="modal-uploader"
      >
        <div>
          <strong>Drag and drop file </strong
          ><span> or browse file on your computer</span>
        </div>
      </a-upload-dragger>
    </a-modal>

    <shelf-builder-preview-modal
      v-if="shelfPreview"
      :shelfImage="shelfImage"
      :loading="loading"
      @download="downloadImage"
      @recreate-shelf-image="createShelfImage"
      @close="shelfPreview = false"
    ></shelf-builder-preview-modal>

    <upload-modal
      v-if="showUploadModal"
      :inDeepDiveProducts="inDeepDiveProducts"
      :surveyID="this.shelfId"
      @close="showUploadModal = false"
      :allShelves="simplifiedAllShelves"
      :gcsProjects="gcsProjects"
      :hideProductsTab="shelf.IsPackTest"
    >
    </upload-modal>

    <a-modal
      title="Add new product"
      :visible="addNewProductModal"
      :footer="null"
      :width="480"
      @cancel="addNewProductModal = false"
      class="ant-modal--dark"
      centered
    >
      <AddNewProduct
        v-if="addNewProductModal"
        :shelfType="shelf.shelftype"
        :shelfId="shelfId"
        :uniqueBrandNames="uniqueBrandNames"
        @add-product="addProduct"
        @close-add-product="addNewProductModal = false"
      ></AddNewProduct>
    </a-modal>

    <a-modal
      title="Currency"
      :visible="changeCurrencyModal"
      :footer="null"
      :width="320"
      @cancel="changeCurrencyModal = false"
      class="ant-modal--dark"
      centered
    >
      <ChangeCurrency
        @close-change-currency="changeCurrencyModal = false"
        @set-currency="setCurrency"
        :shelfId="shelfId"
      ></ChangeCurrency>
    </a-modal>
  </div>
</template>
<script>
import * as jsUtils from "@/utils/jsUtils.js";
import draggable from "vuedraggable";
import * as wsUtils from "@/utils/wsUtils.js";
import html2canvas from "html2canvas";
import ShelfBuilderPreviewModal from "@/components/packTestSurvey/ShelfBuilderPreviewModal.vue";
import Loader from "@/components/general/Loader.vue";
import Tools from "@/components/shelfBuilder/Tools.vue";
import ActiveShelfTools from "@/components/shelfBuilder/ActiveShelfTools.vue";
import ProductPreview from "@/components/shelfBuilder/ProductPreview.vue";
import Popover from "@/components/shelfBuilder/Popover.vue";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import Tabs from "@/components/shelfBuilder/Tabs.vue";
import Upload from "@/components/shelfBuilder/Upload.vue";
import UploadModal from "@/components/shelfBuilder/UploadModal.vue";
import AddNewProduct from "@/components/shelfBuilder/AddNewProduct.vue";
import ChangeCurrency from "@/components/shelfBuilder/ChangeCurrency.vue";
import NestedDraggable from "@/components/shelfBuilder/NestedDraggable.vue";
import { Sortable, MultiDrag } from "sortablejs";

Sortable.mount(new MultiDrag());

export default {
  name: "ShelfBuilder",
  components: {
    draggable,
    ShelfBuilderPreviewModal,
    Loader,
    Tools,
    ProductPreview,
    Popover,
    Tabs,
    ActiveShelfTools,
    Upload,
    UploadModal,
    AddNewProduct,
    ChangeCurrency,
    NestedDraggable,
  },
  data() {
    return {
      sizeDivider: 1,
      tempSizeDivider: 1,
      dragOptions: {
        animation: 150,
        group: "products",
      },
      selectedProduct: null,
      cloneSelectedProduct: null,
      selectedProductFacingWidth: 1,
      selectedProductFacingHeight: 1,
      productPopoverVisible: null,
      productPreviewModal: false,
      uploadModal: false,
      shelf: null,
      allShelves: [],
      originalAllShelves: null,
      shelfWrapperBorderWidth: 6,
      loading: false,
      loadingMessage: "",
      uploadProductImagesLoading: false,
      shelfPreview: false,
      shelfImage: null,
      copiedSize: null,
      copiedSizeProductUPC: null,
      startX: 0,
      startY: 0,
      isResizing: false,
      activeShelfIndex: 0,
      activeUpload: {
        accept: "",
        typeLabel: "",
      },
      showInDeepDiveProducts: false,
      currentShelfBcgColor: null,
      saveDataLoading: false,
      shelfId: null,
      projectName: "",
      fileUploading: "",
      processShelfdataStarted: false,
      user: null,
      leftNavPadding: 96,
      activeProductUPC: null,
      timestamp: new Date().valueOf(),
      showPrice: true,
      inDeepDiveProducts: [],
      showUploadModal: false,
      simplifiedAllShelves: [],
      gcsProjects: [],
      setAllShelvesImagesFinished: false,
      shelfPaddingBottom: 38,
      uploadProcessLoading: [],
      stopUploadProcessLoading: false,
      saveConditionsMet: false,
      addNewProductModal: false,
      uniqueBrandNames: [],
      changeCurrencyModal: false,
      startDragging: false,
      images: [
        ["Real-Time", "Audience", "Conversions"],
        ["Real-Time2", "Audience2", "Conversions2"],
      ],
      selectedProducts: [],
    };
  },
  computed: {
    itemStyles() {
      return this.shelf.bays.map((column) => {
        return column.shelves.map((rowInCol) => {
          return rowInCol.products.map((product) => {
            const calculateTotalHeight = (product) => {
              let totalHeight =
                (product.productHeight * product.heightFacing) /
                this.sizeDivider;

              if (product.stack && product.stack.length > 0) {
                totalHeight += product.stack.reduce((acc, nestedProduct) => {
                  return (
                    acc +
                    calculateTotalHeight(nestedProduct) +
                    this.shelfPaddingBottom / this.sizeDivider
                  );
                }, 0);
              }

              return totalHeight;
            };
            const calculateMaxMinWidth = (product) => {
              let minWidth =
                (product.productWidth * product.widthFacing) / this.sizeDivider;

              if (product.stack && product.stack.length > 0) {
                const maxNestedMinWidth = product.stack.reduce(
                  (max, nestedProduct) => {
                    const nestedMinWidth = calculateMaxMinWidth(nestedProduct);
                    return Math.max(max, nestedMinWidth);
                  },
                  minWidth
                );
                minWidth = Math.max(minWidth, maxNestedMinWidth);
              }

              return minWidth;
            };

            return {
              minWidth: calculateMaxMinWidth(product) + "px",
              height: calculateTotalHeight(product) + "px",
            };
          });
        });
      });
    },
    defaultSizeDivider() {
      if (this.shelf && this.shelf.mainShelfWidth != null) {
        return (
          (this.shelf.mainShelfWidth + this.shelfWrapperBorderWidth * 2) /
          (window.innerWidth - this.leftNavPadding)
        );
      }
      return 0;
    },
    allShelvesUrlCheck() {
      return this.allShelves.map((item) => Boolean(item.imageURL));
    },
    hasDeepDiveProduct() {
      for (const bay of this.shelf.bays) {
        for (const shelf of bay.shelves) {
          const hasDeepDive = shelf.products.some(
            (product) => product.inDeepDive
          );

          if (hasDeepDive) {
            return true;
          }

          const hasDeepDiveInStack = shelf.products.some(
            (product) =>
              product.stack &&
              product.stack.some((stackedProduct) => stackedProduct.inDeepDive)
          );

          if (hasDeepDiveInStack) {
            return true;
          }
        }
      }

      return false;
    },

    dynamicUpload() {
      if (this.activeUpload.typeLabel === "CSV") {
        return this.csvUpload;
      } else if (this.activeUpload.typeLabel === "PSA") {
        return this.psaZipUpload;
      } else {
        return this.uploadBulkProductImages;
      }
    },
    hasUndefinedImages() {
      return this.simplifiedAllShelves.some((item) => {
        return (
          item.shelfImageURLThumb === undefined ||
          item.shelfImageURLUnpriced === undefined ||
          item.shelfImageURLUnpricedThumb === undefined
        );
      });
    },
  },

  methods: {
    toggleSelect(product, event) {
      // Check if the event is a MouseEvent and has ctrlKey and metaKey properties
      const isCtrlKey = event && "ctrlKey" in event && event.ctrlKey;
      const isMetaKey = event && "metaKey" in event && event.metaKey;

      if (!isCtrlKey && !isMetaKey) {
        this.selectedProducts = [product];
      } else {
        const index = this.selectedProducts.indexOf(product);
        if (index > -1) {
          this.selectedProducts.splice(index, 1);
        } else {
          this.selectedProducts.push(product);
        }
      }
    },
    isMultiSelected(product) {
      return (
        this.selectedProducts.includes(product) &&
        this.selectedProducts.length > 1
      );
    },
    /**
     * This method iterates through the shelf structure and searches for a product with a specific UPC in the stack of each root product.
     * If a matching product is found, it transfers all objects from the found product's stack into the root product's stack.
     * The found product's stack is then cleared.
     *
     * @param {Object} product - The product object to search for in the stack of each root product.
     */
    onProductStacked(product) {
      this.shelf.bays.forEach((bay) => {
        bay.shelves.forEach((shelf) => {
          shelf.products.forEach((rootProduct) => {
            if (rootProduct.stack && rootProduct.stack.length > 0) {
              rootProduct.stack.forEach((stackedProduct) => {
                if (stackedProduct.upc === product.upc) {
                  const stackProducts = [...stackedProduct.stack];
                  rootProduct.stack.push(...stackProducts);
                  stackedProduct.stack = [];
                }
              });
            }
          });
        });
      });
    },
    psaZipUpload(zipData) {
      const index = this.uploadProcessLoading.findIndex(
        (shelf) => shelf === this.shelf.shelftype
      );
      if (index === -1) {
        this.uploadProcessLoading.push(this.shelf.shelftype);
      }
      let shelfType = this.shelf.shelftype;

      this.uploadModal = false;
      let form = new FormData();

      form.append("file", zipData.file);
      form.append("surveyId", this.shelfId);
      form.append("shelfType", shelfType);
      form.append("userId", this.user.EncrypteduserID);

      let data = {
        wsName: "UploadProductPSAZIP",
        data: {
          form,
        },
      };
      const psaUpload = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      psaUpload.then((response) => {
        this.processPsaUpload(response, shelfType);
      });
    },
    processPsaUpload(response, shelftype) {
      if (response.Message) {
        this.$notification["warning"]({
          message: `Oops`,
          description: response.Message,
          placement: "bottomLeft",
          duration: 5,
        });
      }
      setTimeout(() => {
        if (response.success) {
          this.processShelfdata(shelftype).then(() => {
            setTimeout(() => {
              this.getProcessStatus(shelftype);
            }, 2000);
          });
        } else {
          this.$notification["error"]({
            message: `Oops! Something went wrong with your upload.`,
            description:
              "There was an error with your upload. Please try again. If it keeps happening, contact support for help.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      }, 500);
    },
    processShelfdata(shelftype) {
      let form = new FormData();
      form.append("surveyId", this.shelfId);
      form.append("shelfType", shelftype);

      let data = {
        wsName: "ProcessShelfdata",
        data: {
          form,
        },
      };

      return new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      }).then((data) => {
        console.log("processShelfdata completed", data);
      });
    },
    getProcessStatus(shelftype) {
      if (this.stopUploadProcessLoading) {
        return;
      }
      const index = this.uploadProcessLoading.findIndex(
        (shelf) => shelf === shelftype
      );
      if (index === -1) {
        this.uploadProcessLoading.push(shelftype);
      }
      let form = new FormData();
      form.append("surveyId", this.shelfId);
      form.append("shelfType", shelftype);

      let data = {
        wsName: "GetProcessStatus",
        data: {
          form,
        },
      };

      const getProcessStatus = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getProcessStatus.then((response) => {
        if (response.ProcessFinished === false) {
          setTimeout(() => {
            this.getProcessStatus(shelftype);
          }, 8000);
        } else {
          const getShelfRenderData = new Promise((resolve) => {
            wsUtils.GetShelfRenderData(
              {
                SurveyId: this.shelfId,
              },
              resolve
            );
          });

          getShelfRenderData.then((data) => {
            console.log("getShelfRenderData completed", data);
            const shelfIndex = data.findIndex(
              (shelf) => shelf.shelftype === shelftype
            );
            if (shelfIndex !== -1) {
              this.allShelves[shelfIndex] = data[shelfIndex];
              this.originalAllShelves[shelfIndex] = data[shelfIndex];
            }

            if (this.shelf.shelftype === shelftype) {
              this.shelf = data[shelfIndex];
              this.sizeDivider =
                this.shelf.mainShelfWidth /
                (window.innerWidth - this.leftNavPadding);

              this.shelf.bcgcolor =
                data[shelfIndex].bcgcolor !== ""
                  ? data[shelfIndex].bcgcolor
                  : "#484848";
              this.currentShelfBcgColor = this.shelf.bcgcolor;
            }
            this.calculateShelfLayoutAndDimensions();

            setTimeout(() => {
              const uploadingIndex = this.uploadProcessLoading.findIndex(
                (shelf) => shelf === shelftype
              );
              this.uploadProcessLoading.splice(uploadingIndex, 1);
              this.originalAllShelves = cloneDeep(this.allShelves);
              this.setSimplifiedAllShelves();
              this.findAllDeepDiveProducts();
            }, 2000);
          });
        }
      });
    },
    setDeepDiveProduct(isInDeepDive) {
      const index = this.inDeepDiveProducts.findIndex(
        (product) =>
          product.upc === this.selectedProduct.upc &&
          product.shelfType === this.shelf.shelftype
      );

      if (isInDeepDive) {
        if (index === -1) {
          const productToAdd = {
            shelfType: this.shelf.shelftype,
            productURL:
              this.selectedProduct.rotated_image_url ||
              this.selectedProduct.productURL,
            upc: this.selectedProduct.upc,
            width: this.selectedProduct.productWidth,
            height: this.selectedProduct.productHeight,
          };

          this.inDeepDiveProducts.push(productToAdd);
        }
      } else {
        if (index !== -1) {
          this.inDeepDiveProducts.splice(index, 1);
        }
      }
      this.selectedProduct.inDeepDive = isInDeepDive;
    },

    findAllDeepDiveProducts() {
      this.inDeepDiveProducts = [];

      for (const shelf of this.allShelves) {
        for (const bay of shelf.bays) {
          for (const shelve of bay.shelves) {
            for (const product of shelve.products) {
              if (product.inDeepDive) {
                const productToAdd = {
                  shelfType: shelf.shelftype,
                  productURL: product.rotated_image_url || product.productURL,
                  upc: product.upc,
                  width: product.productWidth,
                  height: product.productHeight,
                };

                this.inDeepDiveProducts.push(productToAdd);
              }
            }
          }
        }
      }
    },
    resetSizeCopied() {
      setTimeout(() => {
        this.copiedSizeProductUPC = null;
        this.copiedSize = null;
      }, 0);
    },
    openUploadModal(data) {
      this.uploadModal = true;
      this.activeUpload.accept = data.fileType;
      this.activeUpload.typeLabel = data.fileLabel;
    },
    addTest() {
      if (this.allShelves.length > 0) {
        const objToCopy = cloneDeep(this.shelf);
        objToCopy.shelftype = `Test ${this.allShelves.length}`;
        objToCopy.imageName = `Test ${this.allShelves.length}`;
        // objToCopy.imageURL = "";
        // objToCopy.thumbImageURL = "";

        this.allShelves.push(objToCopy);
        this.originalAllShelves.push(objToCopy);

        this.shelf = this.allShelves[this.allShelves.length - 1];
        this.activeShelfIndex = this.allShelves.length - 1;

        this.cloneDeepDiveProducts(objToCopy.shelftype);
        this.setSimplifiedAllShelves();
        this.save(false);
      }
    },
    cloneDeepDiveProducts(shelfType) {
      const modifiedProducts = this.inDeepDiveProducts
        .filter((product) => product.shelfType === "Control")
        .map((product) => ({ ...product, shelfType }));
      this.inDeepDiveProducts.push(...modifiedProducts);
    },
    setActiveShelf(index) {
      this.productPopoverVisible = null;
      this.shelf = this.allShelves[index];
      this.sizeDivider =
        this.shelf.mainShelfWidth / (window.innerWidth - this.leftNavPadding);
      this.currentShelfBcgColor = this.shelf.bcgcolor;
      this.activeShelfIndex = index;
    },
    initResize(event) {
      this.productPopoverVisible = null;
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.isResizing = true;

      window.addEventListener("mousemove", this.startResizing);
      window.addEventListener("mouseup", this.stopResizing);
    },
    startResizing(event) {
      if (this.isResizing) {
        const width =
          this.selectedProduct.productWidth + (event.clientX - this.startX);
        const height =
          this.selectedProduct.productHeight - (event.clientY - this.startY);

        this.selectedProduct.productWidth = width > 0 ? width : 0;
        this.selectedProduct.productHeight = height > 0 ? height : 0;

        this.startX = event.clientX;
        this.startY = event.clientY;
      }
    },
    stopResizing() {
      if (this.isResizing) {
        this.isResizing = false;

        window.removeEventListener("mousemove", this.startResizing);
        window.removeEventListener("mouseup", this.stopResizing);
        this.calculateShelfLayoutAndDimensions();
      }
    },
    copyProductSize() {
      this.productPopoverVisible = null;
      this.copiedSizeProductUPC = this.selectedProduct.upc;
      this.copiedSize = {
        productWidth: this.selectedProduct.productWidth,
        productHeight: this.selectedProduct.productHeight,
      };
      this.$notification["success"]({
        message: "Product size copied",
        description:
          "Product size copied successfully! Please click on the product to which you wish to apply the copied size.",
        placement: "bottomLeft",
        duration: 7,
      });
    },
    deleteProductByUpcConfirm() {
      let _this = this;
      this.$confirm({
        title: "Delete product",
        class: "ant-modal--dark",
        content: () => (
          <div>Are you sure you want to delete the selected product?</div>
        ),
        onOk() {
          _this.deleteProductByUpc();
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    deleteProductByUpc() {
      const deleteProduct = (productsArray, productIndex) => {
        productsArray.splice(productIndex, 1);
        this.$notification["success"]({
          message: "Delete product",
          description: "Product Successfully Deleted.",
          placement: "bottomLeft",
          duration: 5,
        });
        this.productPreviewModal = null;
        this.productPopoverVisible = null;
        this.calculateShelfLayoutAndDimensions();
      };

      for (let bay of this.shelf.bays) {
        for (let shelf of bay.shelves) {
          let productIndex = shelf.products.findIndex(
            (product) => product.upc === this.selectedProduct.upc
          );

          if (productIndex !== -1) {
            deleteProduct(shelf.products, productIndex);
            return;
          } else {
            const productWithStackIndex = shelf.products.findIndex(
              (product) =>
                product.stack &&
                product.stack.some(
                  (stackedProduct) =>
                    stackedProduct.upc === this.selectedProduct.upc
                )
            );

            if (productWithStackIndex !== -1) {
              const stackedProductIndex = shelf.products[
                productWithStackIndex
              ].stack.findIndex(
                (stackedProduct) =>
                  stackedProduct.upc === this.selectedProduct.upc
              );

              if (stackedProductIndex !== -1) {
                deleteProduct(
                  shelf.products[productWithStackIndex].stack,
                  stackedProductIndex
                );
                return;
              }
            }
          }
        }
      }
    },

    onItemClicked(product, event, productType) {
      const multipleClickShelfProducts = jsUtils.readCookie(
        "multipleClickShelfProducts"
      );

      if (!multipleClickShelfProducts && productType === "main-product") {
        this.$notification["info"]({
          message: "How to select multiple products",
          description:
            "Hold down Ctrl (Cmd on Mac) while clicking to select multiple non-stacked products.",
          placement: "bottomLeft",
          duration: 15,
        });

        jsUtils.setCookie("multipleClickShelfProducts", true, 30);
      }

      this.toggleSelect(product, event);
      if (this.selectedProduct && this.selectedProduct.upc === product.upc) {
        this.selectedProduct = null;
        this.cloneSelectedProduct = null;
        this.productPopoverVisible = null;
        return;
      }
      this.selectedProduct = product;
      this.cloneSelectedProduct = cloneDeep(this.selectedProduct);

      if (
        this.copiedSize &&
        this.copiedSizeProductUPC !== this.selectedProduct.upc
      ) {
        this.selectedProduct.productWidth = this.copiedSize.productWidth;
        this.selectedProduct.productHeight = this.copiedSize.productHeight;
        this.copiedSize = null;
        this.$notification["success"]({
          message: "Product size applied",
          description:
            "Size has been successfully applied to the selected product.",
          placement: "bottomLeft",
          duration: 5,
        });
        this.copiedSizeProductUPC = null;
        this.calculateShelfLayoutAndDimensions();
      } else {
        this.selectedProductFacingWidth = product.widthFacing;
        this.selectedProductFacingHeight = product.heightFacing;

        // Toggle product popover visibility
        this.productPopoverVisible =
          this.productPopoverVisible === product.upc ? null : product.upc;

        const productElement = this.getProductElement(product.upc);
        if (productElement) {
          const productElementRect = productElement.getBoundingClientRect();
          const itemPopoverWidth = 300;
          const itemPopoverHeight = 182;

          this.setPopoverPosition(
            productElementRect,
            itemPopoverWidth,
            itemPopoverHeight
          );
        }
      }
    },

    getProductElement(upc) {
      // Check for ref in the parent component first
      let productElement = this.$refs[`productRef_${upc}`];
      if (productElement && productElement[0]) {
        return productElement[0];
      }

      const nestedDraggables = this.$refs.nestedDraggableRef;

      // Recursive function to search through nested draggable instances
      function searchNestedDraggables(draggables) {
        for (let i = 0; i < draggables.length; i++) {
          const nestedDraggable = draggables[i];

          // Access specific refs within each nested draggable
          for (let key in nestedDraggable.$refs) {
            if (key.startsWith("nestedProductRef_")) {
              const refElement =
                nestedDraggable.$refs[key] && nestedDraggable.$refs[key][0];
              if (refElement && refElement.dataset.upc === upc) {
                return refElement;
              }
            }

            // If the nested draggable has its own nested draggables, search them too
            if (nestedDraggable.$refs.childComponentRef) {
              const result = searchNestedDraggables(
                nestedDraggable.$refs.childComponentRef
              );
              if (result) {
                return result;
              }
            }
          }
        }
        return null;
      }

      // Start the recursive search
      return nestedDraggables ? searchNestedDraggables(nestedDraggables) : null;
    },

    setPopoverPosition(elementRect, popoverWidth, popoverHeight) {
      const shelfBuilder = document.querySelector(".shelf-builder");
      const scrollX = shelfBuilder.scrollLeft;
      const scrollY = shelfBuilder.scrollTop;
      const leftSideNavWidth = 96;

      const elementCenterX = elementRect.x + elementRect.width / 2;
      const elementCenterY = elementRect.y + elementRect.height / 2;
      const spaceRight =
        window.innerWidth - (elementRect.x + elementRect.width);
      const spaceLeft = elementRect.x;
      const spaceBelow =
        window.innerHeight - (elementRect.y + elementRect.height);

      let popoverLeft, popoverTop, cssClass;

      // Vertical centering for side positioning should account for the 10 pixels offset
      const sideVerticalCenter = elementCenterY - popoverHeight / 2;

      // Initial top position assuming there is enough space below
      popoverTop = elementRect.y + elementRect.height + 10;

      // Adjust left position if there isn't enough space on the right
      if (spaceRight < popoverWidth + 10) {
        popoverLeft = elementRect.x - popoverWidth - 10;
        popoverTop = sideVerticalCenter; // Center vertically to the side
        cssClass = "left";
      }
      // Adjust left position if there isn't enough space on the left
      else if (spaceLeft < popoverWidth + 10) {
        popoverLeft = elementRect.x + elementRect.width + 10;
        popoverTop = sideVerticalCenter; // Center vertically to the side
        cssClass = "right";
      }
      // Default to horizontal centering if there's enough space on either side
      else {
        popoverLeft = elementCenterX - popoverWidth / 2;
      }

      // Adjust top position if there isn't enough space below
      if (spaceBelow < popoverHeight + 10 && spaceLeft < popoverWidth + 10) {
        popoverLeft = elementRect.x + elementRect.width + 10;
        popoverTop = sideVerticalCenter; // Center vertically to the side
      } else if (
        spaceBelow < popoverHeight + 10 &&
        spaceRight < popoverWidth + 10
      ) {
        popoverLeft = elementRect.x - popoverWidth - 10;
        popoverTop = sideVerticalCenter; // Center vertically to the side
      } else if (spaceBelow < popoverHeight + 10) {
        popoverTop = elementRect.y - popoverHeight - 10;
        cssClass = "top";
      }

      // Ensure popover is within viewport horizontally
      popoverLeft = Math.max(10, popoverLeft);
      popoverLeft = Math.min(
        window.innerWidth - popoverWidth - 10,
        popoverLeft
      );

      // Ensure popover is within viewport vertically
      popoverTop = Math.max(10, popoverTop);
      popoverTop = Math.min(
        window.innerHeight - popoverHeight - 10,
        popoverTop
      );

      // Set the calculated position
      this.selectedProduct.popoverPosition = {
        left: popoverLeft - leftSideNavWidth + scrollX,
        top: popoverTop + scrollY,
        class: cssClass,
      };
    },

    updateProductFacing() {
      let isProductUpdated = false;

      // Funkcija za ažuriranje proizvoda
      const updateProduct = (productsArray, productIndex) => {
        this.$set(productsArray, productIndex, {
          ...this.selectedProduct,
          widthFacing: this.selectedProductFacingWidth,
          heightFacing: this.selectedProductFacingHeight,
        });
        isProductUpdated = true;
      };

      for (const bay of this.shelf.bays) {
        for (const shelf of bay.shelves) {
          // Pronađi indeks proizvoda u shelf.products
          let selectedProductIndex = shelf.products.findIndex(
            (product) => product.upc === this.selectedProduct.upc
          );

          if (selectedProductIndex !== -1) {
            updateProduct(shelf.products, selectedProductIndex);
            break;
          } else {
            // Ako nije pronađen u shelf.products, pokušaj pronaći u product.stack
            selectedProductIndex = shelf.products
              .map((product) => product.stack)
              .flat()
              .findIndex((product) => product.upc === this.selectedProduct.upc);

            if (selectedProductIndex !== -1) {
              const productIndexInShelf = shelf.products.findIndex(
                (product) =>
                  product.stack &&
                  product.stack.some(
                    (stackProduct) =>
                      stackProduct.upc === this.selectedProduct.upc
                  )
              );
              updateProduct(
                shelf.products[productIndexInShelf].stack,
                selectedProductIndex
              );
              break;
            }
          }
        }
        if (isProductUpdated) break;
      }

      if (isProductUpdated) {
        this.calculateShelfLayoutAndDimensions();
      }
    },
    onStartDragging() {
      this.productPopoverVisible = null;
      this.startDragging = true;
    },

    onEndDragging(event) {
      if (this.selectedProducts.length <= 1) {
        // Use native draggable method if only one product is selected
        this.selectedProducts = [];
        this.calculateShelfLayoutAndDimensions();
        this.startDragging = false;
        return;
      }

      const newBayIndex = parseInt(
        event.to
          .closest(".shelf-builder__inner-col")
          .getAttribute("data-bay-index"),
        10
      );
      const newRow = parseInt(event.to.getAttribute("data-row-index"), 10);
      const newIndex = event.newIndex;
      const movedProductUPC = event.item.getAttribute("data-upc");

      let productsToMove = [...this.selectedProducts];

      // Check if the drop target is a product
      const dropTargetProduct = event.to.closest(".shelf-builder__item");
      if (dropTargetProduct) {
        const targetProductUPC = dropTargetProduct.getAttribute("data-upc");
        let targetProduct = null;

        // Find the target product in the shelf data
        this.shelf.bays.forEach((bay) => {
          bay.shelves.forEach((row) => {
            row.products.forEach((product) => {
              if (product.upc === targetProductUPC) {
                targetProduct = product;
              }
            });
          });
        });

        if (targetProduct) {
          // Exclude the product that Draggable already moved
          productsToMove = this.selectedProducts.filter(
            (product) => product.upc !== movedProductUPC
          );
          // Remove selected products from all rows in all bays before adding to the stack
          this.shelf.bays.forEach((bay) => {
            bay.shelves.forEach((row) => {
              row.products = row.products.filter(
                (product) =>
                  !productsToMove.includes(product) &&
                  product.upc !== movedProductUPC
              );
            });
          });

          // Push the selected products into the target product's stack
          productsToMove.forEach((product) => {
            targetProduct.stack.push(product);
          });

          // Clear selected products
          this.selectedProducts = [];
          this.calculateShelfLayoutAndDimensions();
          this.startDragging = false;
          return;
        }
      }

      // If not dropping onto another product, proceed with moving to the new row and bay
      this.shelf.bays.forEach((bay) => {
        bay.shelves.forEach((row) => {
          row.products = row.products.filter(
            (product) =>
              !productsToMove.includes(product) &&
              product.upc !== movedProductUPC
          );
        });
      });

      // Insert products into the new row in the specified bay at the specified index
      this.shelf.bays[newBayIndex].shelves[newRow].products.splice(
        newIndex,
        0,
        ...productsToMove
      );

      this.selectedProducts = [];
      this.calculateShelfLayoutAndDimensions();
      this.startDragging = false;
    },

    csvUpload(data) {
      this.shelfImage = null;
      this.uploadModal = false;
      let form = new FormData();
      form.append("file", data.file);
      form.append("surveyId", this.shelfId);
      form.append("userId", this.user.EncrypteduserID);
      form.append("shelfType", this.shelf.shelftype);

      const getShelfRenderDetails = new Promise((resolve) => {
        wsUtils.GetShelfRenderDetails(
          {
            form,
          },
          resolve
        );
      });

      getShelfRenderDetails.then((response) => {
        setTimeout(() => {
          if (response.success) {
            this.setCsvData(response);
          } else {
            this.$notification["error"]({
              message: "CSV Upload Failed",
              description: response.message,
              placement: "bottomLeft",
              duration: 5,
            });
          }
        }, 500);
      });
    },
    setCsvData(data) {
      this.shelf = data.message;
      this.shelf.bcgcolor = "#484848";
      this.currentShelfBcgColor = this.shelf.bcgcolor;
      const index = this.allShelves.findIndex(
        (shelf) => shelf.shelftype === this.shelf.shelftype
      );
      if (index !== -1) {
        this.allShelves.splice(index, 1, this.shelf);
        this.originalAllShelves.splice(index, 1, this.shelf);
      } else {
        this.allShelves.push(this.shelf);
        if (this.originalAllShelves === null) {
          this.originalAllShelves = data.message;
        } else {
          this.originalAllShelves.push(this.shelf);
        }
      }

      this.sizeDivider =
        data.message.mainShelfWidth / (window.innerWidth - this.leftNavPadding);
      this.calculateShelfLayoutAndDimensions();
    },
    calculateShelfLayoutAndDimensions() {
      this.$nextTick(() => {
        let maxHeightsPerRow = {};
        let totalMaxWidthOfAllBays = 0;

        this.shelf.bays.forEach((bay) => {
          let maxWidthBay = 0;

          this.shelf.bays.forEach((bay) => {
            const matchingShelfBays = this.shelf.bays.filter(
              (otherBay) => otherBay.noOfShelf === bay.noOfShelf
            );
            if (matchingShelfBays.length > 1) {
              bay.shelves.forEach((shelf, shelfIndex) => {
                let maxHeightProduct = 0;

                shelf.products.forEach((product) => {
                  const refName = `productRef_${product.upc}`;
                  const productElement = this.$refs[refName][0];
                  const rect = productElement.getBoundingClientRect();
                  maxHeightProduct = Math.max(
                    maxHeightProduct,
                    rect.height * this.sizeDivider
                  );
                });

                maxHeightsPerRow[shelfIndex] = Math.max(
                  maxHeightsPerRow[shelfIndex] || 0,
                  maxHeightProduct
                );

                shelf.shelfHeight = Math.round(
                  maxHeightsPerRow[shelfIndex] + this.shelfPaddingBottom
                );
              });
            }
          });

          const shelfBuilderInnerElement = this.$refs.shelfBuilderInner;
          const shelfBuilderInnerRect =
            shelfBuilderInnerElement.getBoundingClientRect();

          bay.shelves.forEach((shelf) => {
            let sumWidthProducts = 0;
            let totalProductsCount = 0;

            shelf.products.forEach((product, productIndex) => {
              const refName = `productRef_${product.upc}`;
              const productElement = this.$refs[refName][0];
              const rect = productElement.getBoundingClientRect();

              if (productIndex > 0) {
                const previousProduct = shelf.products[productIndex - 1];

                if (
                  previousProduct.stack &&
                  Array.isArray(previousProduct.stack)
                ) {
                  product.productPosition =
                    previousProduct.productPosition +
                    previousProduct.stack.length +
                    1;
                } else {
                  product.productPosition = previousProduct.productPosition + 1;
                }
              } else {
                product.productPosition = 1;
              }
              product.X1 = Math.round(
                (rect.left - shelfBuilderInnerRect.left) * this.sizeDivider
              );
              product.y1 = Math.round(
                (rect.top - shelfBuilderInnerRect.top) * this.sizeDivider
              );
              product.x2 = Math.round(
                (rect.left +
                  productElement.offsetWidth -
                  shelfBuilderInnerRect.left) *
                  this.sizeDivider
              );
              product.y2 = Math.round(
                (rect.top +
                  productElement.offsetHeight -
                  shelfBuilderInnerRect.top) *
                  this.sizeDivider
              );

              let productWidthFacingSum =
                product.productWidth * product.widthFacing;

              if (product.stack && product.stack.length > 0) {
                product.stack.forEach((stackedProduct, productIndex) => {
                  const productElement = this.getProductElement(
                    stackedProduct.upc
                  );
                  const rect = productElement.getBoundingClientRect();
                  const stackedProductWidthFacingSum =
                    stackedProduct.productWidth * stackedProduct.widthFacing;
                  stackedProduct.productPosition =
                    product.productPosition + productIndex + 1;

                  stackedProduct.stackPosition = productIndex + 1;
                  stackedProduct.X1 = Math.round(
                    (rect.left - shelfBuilderInnerRect.left) * this.sizeDivider
                  );
                  stackedProduct.y1 = Math.round(
                    (rect.top - shelfBuilderInnerRect.top) * this.sizeDivider
                  );
                  stackedProduct.x2 = Math.round(
                    (rect.left +
                      productElement.offsetWidth -
                      shelfBuilderInnerRect.left) *
                      this.sizeDivider
                  );
                  stackedProduct.y2 = Math.round(
                    (rect.top +
                      productElement.offsetHeight -
                      shelfBuilderInnerRect.top) *
                      this.sizeDivider
                  );
                  stackedProduct.parentUPC = product.upc;

                  productWidthFacingSum = Math.max(
                    productWidthFacingSum,
                    stackedProductWidthFacingSum
                  );
                });
                totalProductsCount += product.stack.length;
              }

              sumWidthProducts += productWidthFacingSum;
              totalProductsCount += 1;
            });
            shelf.noOfProducts = totalProductsCount;
            maxWidthBay = Math.max(
              maxWidthBay,
              sumWidthProducts + shelf.emptyWidth
            );
          });

          bay.bayWidth = Math.round(maxWidthBay);
          totalMaxWidthOfAllBays += maxWidthBay;
          setTimeout(() => {
            bay.bayHeight = Math.round(
              shelfBuilderInnerElement.getBoundingClientRect().height
            );
            this.shelf.mainShelfHeight = Math.round(
              shelfBuilderInnerElement.getBoundingClientRect().height
            );
          }, 100);
        });
        this.shelf.mainShelfWidth = Math.round(totalMaxWidthOfAllBays);
      });
    },

    previewShelfImage() {
      const originalShelf = this.originalAllShelves.find(
        (o) => o.shelftype === this.shelf.shelftype
      );

      if (
        !this.shelf.imageURL ||
        (originalShelf && !isEqual(this.shelf, originalShelf))
      ) {
        this.createShelfImage();
      } else {
        this.shelfImage = this.shelf.imageURL;
        this.shelfPreview = true;
      }
    },
    createShelfImage(attempt = 1) {
      this.$nextTick(() => {
        if (!this.shelf) {
          return;
        }

        this.showPrice = false;
        this.timestamp = Date.now();
        let tempShowInDeepDiveProducts = this.showInDeepDiveProducts;
        this.productPopoverVisible = null;
        this.showInDeepDiveProducts = false;
        const shelfElement = this.$refs.shelfBuilderInner;
        this.loadingMessage = "Creating Shelf Image... Please Wait.";
        this.loading = true;
        this.tempSizeDivider = this.sizeDivider;
        this.sizeDivider = 1;

        setTimeout(() => {
          this.calculateShelfLayoutAndDimensions();
          setTimeout(() => {
            html2canvas(shelfElement, {
              width:
                this.shelf.mainShelfWidth + this.shelfWrapperBorderWidth * 2,
              height: this.shelf.mainShelfHeight,
              useCORS: true,
              imageTimeout: 0,
              allowTaint: false,
              scale: 1,
            })
              .then((canvas) => {
                const imageDataUrl = canvas.toDataURL("image/jpeg");
                if (!imageDataUrl || imageDataUrl === "data:,") {
                  console.error("Image creation failed, retrying...", attempt);
                  if (attempt < 3) {
                    this.createShelfImage(attempt + 1); // Ponovni pokušaj sa povećanim brojem pokušaja
                  } else {
                    console.error(
                      "Failed to create image after several attempts."
                    );
                    // Ovde možete dodati logiku za obradu konačnog neuspeha
                  }
                } else {
                  this.showInDeepDiveProducts = tempShowInDeepDiveProducts;
                  this.shelfImage = imageDataUrl;
                  this.shelfPreview = true;
                  this.sizeDivider = this.tempSizeDivider;
                  this.showPrice = true;
                  this.loading = false;
                  this.loadingMessage = "";
                  this.originalAllShelves = cloneDeep(this.allShelves);
                  this.uploadShelfImages(imageDataUrl);
                }
              })
              .catch((error) => {
                console.error("Error generating shelf image: ", error);
              });
          }, 3000);
        }, 1000);
      });
    },

    getGCSProjects() {
      this.productPopoverVisible = null;
      this.loadingMessage =
        "Retrieving your projects. Please hold on a moment...";
      this.loading = true;
      let data = {
        wsName: this.shelf.IsPackTest ? "GetPackShelfs" : "GetGcsProjects",
        data: {
          SurveyID: this.shelfId,
        },
      };
      const getGCSProjects = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getGCSProjects.then((data) => {
        if (data.Shelfs.length > 0) {
          this.gcsProjects = data.Shelfs.map((project, index) => {
            const tempShelfName = index === 0 ? "Control" : `Test ${index}`;

            if (project.ShelfName && project.ShelfImageList) {
              project.ShelfImageList = project.ShelfImageList.map((image) => {
                return {
                  ...image,
                  TempShelfName: tempShelfName,
                  ShelfName: project.ShelfName,
                };
              });
            }
            if (project.ShelfName && project.DeepDive) {
              project.DeepDive = project.DeepDive.map((product) => {
                return {
                  ...product,
                  gcsShelfName: project.ShelfName,
                };
              });
            }

            return { ...project, TempShelfName: tempShelfName };
          });

          if (
            isEqual(this.allShelves, this.originalAllShelves) &&
            !this.hasUndefinedImages
          ) {
            this.showUploadModal = true;
            this.loading = false;
            this.loadingMessage = "";
          } else {
            this.setAllShelvesImages();
          }
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description:
              "Currently, there are no active GCS projects. To proceed, please create a new project using the GCS Wizard.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.loadingMessage = "";
          this.loading = false;
        }
      });
    },
    async setAllShelvesImages() {
      this.setAllShelvesImagesFinished = false;
      let index = 0;
      const priceCases = [true, false];
      this.productPopoverVisible = null;
      this.showInDeepDiveProducts = false;
      this.tempSizeDivider = this.sizeDivider;
      this.sizeDivider = 1;
      const shelfElement = this.$refs.shelfBuilderInner;

      for (const shelf of this.allShelves) {
        this.activeShelfIndex = index;
        this.shelf = shelf;
        this.currentShelfBcgColor = this.shelf.bcgcolor;
        this.timestamp = Date.now();
        let tempShowInDeepDiveProducts = this.showInDeepDiveProducts;

        for (const showPriceCase of priceCases) {
          this.loadingMessage = `Creating and uploading Shelf Images... Please wait, this might take a while. <br>${index} / ${this.allShelves.length}`;
          this.showPrice = showPriceCase;
          await new Promise((resolve, reject) => {
            setTimeout(() => {
              this.calculateShelfLayoutAndDimensions();
              setTimeout(() => {
                html2canvas(shelfElement, {
                  useCORS: true,
                  imageTimeout: 0,
                  allowTaint: false,
                  width:
                    this.shelf.mainShelfWidth +
                    this.shelfWrapperBorderWidth * 2,
                  height: this.shelf.mainShelfHeight,
                  scale: 1,
                })
                  .then((canvas) => {
                    this.showInDeepDiveProducts = tempShowInDeepDiveProducts;
                    this.uploadAllShelfImages(
                      canvas.toDataURL("image/jpeg"),
                      this.shelf.shelftype,
                      this.showPrice ? "imageURL" : "shelfImageUnpriced"
                    );
                    resolve();
                  })
                  .catch((error) => {
                    console.error("Error generating shelf image V1: ", error);
                    reject(error);
                  });
              }, 3000);
            }, 1000);
          });
        }

        index++;
      }

      this.sizeDivider = this.tempSizeDivider;
      this.activeShelfIndex = 0;
      this.shelf = this.allShelves[0];
      this.showPrice = true;
      this.currentShelfBcgColor = this.shelf.bcgcolor;
      this.setAllShelvesImagesFinished = true;
    },
    setSimplifiedAllShelves() {
      this.simplifiedAllShelves = this.allShelves.map((shelf) => ({
        shelfType: shelf.shelftype,
        shelfWidth: shelf.mainShelfWidth,
        shelfHeight: shelf.mainShelfHeight,
        shelfImageURL: shelf.imageURL,
        shelfImageURLThumb: shelf.imageURLThumb,
        shelfImageURLUnpriced: shelf.shelfImageUnpriced,
        shelfImageURLUnpricedThumb: shelf.shelfImageUnpricedThumb,
      }));
    },
    uploadAllShelfImages(imageData, shelftype, imageType) {
      const imageBlob = this.base64ToBlob(imageData, "image/jpeg");
      let form = new FormData();
      form.append("file", imageBlob);
      form.append("surveyId", this.shelfId);
      form.append("shelfType", shelftype);
      form.append("isPriced", imageType === "imageURL" ? true : false);

      const uploadShelfImages = new Promise((resolve) => {
        wsUtils.UploadShelfImages(
          {
            wsName: "UploadShelfImages",
            form,
          },
          resolve
        );
      });

      uploadShelfImages
        .then((data) => {
          if (data.success) {
            let shelf = this.allShelves.find(
              (item) => item.shelftype === shelftype
            );
            if (shelf) {
              shelf[imageType] = data.imageURL;
              shelf[`${imageType}Thumb`] = data.thumbImageURL;
            }
            this.setSimplifiedAllShelves();

            if (this.setAllShelvesImagesFinished) {
              this.originalAllShelves = cloneDeep(this.allShelves);
              this.showUploadModal = true;
              this.loading = false;
              this.loadingMessage = "";
            }
          } else {
            this.handleError(data, "Upload Shelf Image");
          }
        })
        .catch((error) => {
          this.handleError(error, "Upload Shelf Image");
        });
    },
    downloadImage() {
      if (!this.shelfImage) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = this.shelfImage;
      downloadLink.download = "shelf-preview.jpg";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    },
    base64ToBlob(base64, mimeType) {
      const byteString = atob(base64.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeType });
    },
    async uploadShelfImages(imageDataURL) {
      const imageBlob = this.base64ToBlob(
        imageDataURL ? imageDataURL : this.shelfImage,
        "image/jpeg"
      );
      let form = new FormData();
      form.append("file", imageBlob);
      form.append("surveyId", this.shelfId);
      form.append("shelfType", this.shelf.shelftype);
      form.append("isPriced", false);
      this.loadingMessage = "Saving Shelf Image... Please wait.";
      this.loading = true;

      try {
        const data = await new Promise((resolve, reject) => {
          wsUtils.UploadShelfImages(
            {
              wsName: "UploadShelfImages",
              form,
            },
            (response) => {
              if (response.success) {
                resolve(response);
              } else {
                reject(new Error("Upload nije uspeo"));
              }
            }
          );
        });
        this.shelf.imageURL = data.imageURL;
        this.shelf.thumbImageURL = data.thumbImageURL;
        this.shelf.imageName = this.shelf.shelftype;
        this.uploadProductImagesLoading = false;
        this.loadingMessage = "";
        this.loading = false;
        this.setSimplifiedAllShelves();
        this.save(false);
      } catch (error) {
        this.handleError(error, "Upload Shelf Image");
      }
    },
    handleError(error, errorTitle) {
      let message =
        "An error has occurred. Please try again or contact support.";
      if (error && error.status === 500) {
        message = "Server error. Please try again later or contact support.";
      }

      this.$notification["error"]({
        message: errorTitle,
        description: message,
        placement: "bottomLeft",
        duration: 5,
      });

      this.loading = false;
      this.loadingMessage = "";
    },
    save(notification) {
      this.saveDataLoading = true;
      const shelfData = {
        SurveyId: this.shelfId,
        UserId: this.user.EncrypteduserID,
        shelf: [...this.allShelves],
      };

      const saveShelfRenderDetails = new Promise((resolve) => {
        wsUtils.SaveShelfRenderDetails(shelfData, resolve);
      });

      saveShelfRenderDetails.then((data) => {
        if (data.Success) {
          this.originalAllShelves = cloneDeep(this.allShelves);

          if (notification) {
            this.$notification["success"]({
              message: "Saving data",
              description: "Data successfully saved.",
              placement: "bottomLeft",
              duration: 5,
            });
          }
        }
        this.saveDataLoading = false;
      });
    },
    uploadProductImages(data, upc) {
      let form = new FormData();
      form.append("file", data.file);
      this.uploadProductImagesLoading = true;

      const uploadShelfImages = new Promise((resolve) => {
        wsUtils.UploadShelfBuilder(
          {
            surveyId: this.shelfId,
            wsName: "UploadProductsImages",
            form,
            shelfType: this.shelf.shelftype,
            upc: upc,
          },
          resolve
        );
      });

      uploadShelfImages
        .then((data) => {
          if (data.success) {
            this.$notification["success"]({
              message: "Upload Product Image",
              description: "Product image successfully uploaded.",
              placement: "bottomLeft",
              duration: 5,
            });
            this.selectedProduct.productURL = data.imageURL;
            this.selectedProduct.thumbProductURL = data.ThumbImageURL;
            this.selectedProduct.rotated_image_url = "";
            this.selectedProduct.thumb_rotated_image_url = "";
            this.uploadProductImagesLoading = false;
          } else {
            this.$notification["error"]({
              message: "Upload Product Image",
              description:
                "Something was wrong, please try again or contact our support.",
              placement: "bottomLeft",
              duration: 5,
            });
          }
        })
        .catch((error) => {
          this.handleError(error, "Upload Product Image");
        });
    },
    calculateNaturalImgSize(image) {
      const aspectRatio = image.naturalWidth / image.naturalHeight;
      const widthRatio = this.selectedProduct.productWidth / image.naturalWidth;
      const heightRatio =
        this.selectedProduct.productHeight / image.naturalHeight;

      if (Math.abs(widthRatio - 1) < Math.abs(heightRatio - 1)) {
        this.selectedProduct.productHeight = Math.round(
          this.selectedProduct.productWidth / aspectRatio
        );
      } else {
        this.selectedProduct.productWidth = Math.round(
          this.selectedProduct.productHeight * aspectRatio
        );
      }
      this.calculateShelfLayoutAndDimensions();
    },
    downloadCsv(type) {
      this.loadingMessage = "Exporting CSV... Please Wait.";
      this.loading = true;

      const shelfData = {
        SurveyId: this.shelfId,
        UserId: this.user.EncrypteduserID,
        shelf: type === "all" ? [...this.allShelves] : [this.shelf],
      };

      const downloadShelfRenderDetails = new Promise((resolve) => {
        wsUtils.DownloadShelfRenderDetails(shelfData, resolve);
      });

      downloadShelfRenderDetails.then((data) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        let fileName = "";
        if (type === "all") {
          fileName = `${this.$route.params.cipId}_All.xlsx`;
        } else {
          const shelfTypeNoSpaces = this.shelf.shelftype.replace(/\s+/g, "");
          fileName = `${this.$route.params.cipId}_${shelfTypeNoSpaces}.xlsx`;
        }
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.loadingMessage = "";
        this.loading = false;
      });
    },
    uploadBulkProductImages(data) {
      this.uploadModal = false;
      this.loadingMessage = "Uploading the product images... Please Wait.";
      this.loading = true;
      let form = new FormData();
      form.append("file", data.file);

      const uploadBulkProductImage = new Promise((resolve) => {
        wsUtils.UploadShelfBuilder(
          {
            surveyId: this.shelfId,
            wsName: "UploadBulkProductImage",
            form,
            shelfType: this.shelf.shelftype,
          },
          resolve
        );
      });

      uploadBulkProductImage.then((data) => {
        data.forEach((newProductImage) => {
          this.shelf.bays.forEach((bay) => {
            bay.shelves.forEach((shelf) => {
              shelf.products.forEach((product) => {
                if (product.upc.toString() === newProductImage.UPC) {
                  product.productURL = newProductImage.imageURL;
                  product.thumbProductURL = newProductImage.ThumbImageURL;
                  product.rotated_image_url = "";
                  product.thumb_rotated_image_url = "";
                } else if (product.stack) {
                  const stackProduct = product.stack.find(
                    (stackedProduct) =>
                      stackedProduct.upc.toString() === newProductImage.UPC
                  );

                  if (stackProduct) {
                    stackProduct.productURL = newProductImage.imageURL;
                    stackProduct.thumbProductURL =
                      newProductImage.ThumbImageURL;
                    stackProduct.rotated_image_url = "";
                    stackProduct.thumb_rotated_image_url = "";
                  }
                }
              });
            });
          });
        });
        this.loadingMessage = "";
        this.loading = false;
        this.$notification["success"]({
          message: "Upload Products Images",
          description: "The product images have been successfully uploaded.",
          placement: "bottomLeft",
          duration: 5,
        });
      });
    },
    /**
     * Deletes the shelf at the specified index and removes all products belonging to that shelf type from the inDeepDiveProducts array.
     *
     * @param {number} index - The index of the shelf to be deleted.
     */
    deleteShelf(index) {
      // Delete products from the inDeepDiveProducts array that belong to the shelf being deleted
      let shelfType = this.allShelves[index].shelftype;
      this.inDeepDiveProducts = this.inDeepDiveProducts.filter(
        (product) => product.shelfType !== shelfType
      );

      // Delete the shelf from the allShelves array
      this.allShelves.splice(index, 1);
      this.originalAllShelves.splice(index, 1);

      this.shelf = this.allShelves[0];
      this.currentShelfBcgColor = this.shelf.bcgcolor;
      this.activeShelfIndex = 0;
      this.setSimplifiedAllShelves();
      this.save(false);
    },
    getProjectDetails() {
      this.loadingMessage = "Loading... Please Wait.";
      this.loading = true;
      const getShelfRenderData = new Promise((resolve) => {
        wsUtils.GetShelfRenderData(
          {
            SurveyId: this.shelfId,
          },
          resolve
        );
      });

      getShelfRenderData.then((data) => {
        this.shelf =
          data.length > 0 ? (this.shelf = data[0]) : (this.shelf = null);
        if (this.shelf) {
          this.allShelves.push(...data);

          this.allShelves.forEach((shelf) => {
            if (shelf.ProcessStarted && !shelf.ProcessFinished) {
              this.getProcessStatus(shelf.shelftype);
            }
          });

          this.shelf.bcgcolor =
            data[0].bcgcolor !== "" ? data[0].bcgcolor : "#484848";
          this.currentShelfBcgColor = this.shelf.bcgcolor;
          this.calculateShelfLayoutAndDimensions();

          setTimeout(() => {
            this.sizeDivider =
              this.shelf.mainShelfWidth /
              (window.innerWidth - this.leftNavPadding);
            this.originalAllShelves = cloneDeep(this.allShelves);
            this.setSimplifiedAllShelves();
            this.findAllDeepDiveProducts();
          }, 2000);
        }

        setTimeout(() => {
          this.loading = false;
        }, 2000);
      });
    },

    async checkSaveConditions() {
      let tasksToComplete = 0;
      let tasksCompleted = 0;
      this.saveConditionsMet = false;

      for (let index = 0; index < this.allShelves.length; index++) {
        const shelf = this.allShelves[index];
        const condition1 = !shelf.imageURL;
        const condition2 = !isEqual(shelf, this.originalAllShelves[index]);

        if (condition1 || condition2) {
          this.saveConditionsMet = true;
          tasksToComplete++;
          await this.createdImage(shelf).then(() => tasksCompleted++);
        }
      }

      if (!this.saveConditionsMet) {
        this.save(true);
      } else if (tasksCompleted === tasksToComplete) {
        this.save(true);
      }
    },

    async createdImage(shelf) {
      this.loadingMessage = "Creating Shelf Image... Please wait.";
      this.loading = true;
      this.productPopoverVisible = null;
      this.showInDeepDiveProducts = false;
      this.tempSizeDivider = this.sizeDivider;
      this.sizeDivider = 1;
      const shelfElement = this.$refs.shelfBuilderInner;
      this.shelf = shelf;
      this.currentShelfBcgColor = this.shelf.bcgcolor;
      this.timestamp = Date.now();
      let tempShowInDeepDiveProducts = this.showInDeepDiveProducts;
      this.showPrice = false;

      const attemptToCreateImage = async (attempt = 1) => {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            this.calculateShelfLayoutAndDimensions();
            setTimeout(async () => {
              try {
                const canvas = await html2canvas(shelfElement, {
                  useCORS: true,
                  imageTimeout: 0,
                  allowTaint: false,
                  width:
                    this.shelf.mainShelfWidth +
                    this.shelfWrapperBorderWidth * 2,
                  height: this.shelf.mainShelfHeight,
                  scale: 1,
                });
                const imageDataUrl = canvas.toDataURL("image/jpeg");
                if (!imageDataUrl || imageDataUrl === "data:,") {
                  console.error("Image creation failed, retrying...", attempt);
                  if (attempt < 3) {
                    await attemptToCreateImage(attempt + 1);
                  } else {
                    reject(
                      new Error(
                        "Failed to create image after several attempts."
                      )
                    );
                  }
                } else {
                  this.showInDeepDiveProducts = tempShowInDeepDiveProducts;
                  this.showPrice = true;
                  this.sizeDivider = this.tempSizeDivider;
                  await this.uploadShelfImages(imageDataUrl);
                  resolve();
                }
              } catch (error) {
                reject(error);
              }
            }, 3000);
          }, 1000);
        });
      };

      try {
        await attemptToCreateImage();
      } catch (error) {
        console.error(
          "An error occurred during the image creation process:",
          error
        );
      }
    },

    updateUPCAllTests() {
      const keysToCompare = [
        "price",
        "weight",
        "productName",
        "X1",
        "x2",
        "y1",
        "y2",
      ];

      const selectedProductKeys = keysToCompare.reduce((acc, key) => {
        acc[key] = this.selectedProduct[key];
        return acc;
      }, {});

      const cloneSelectedProductKeys = keysToCompare.reduce((acc, key) => {
        acc[key] = this.cloneSelectedProduct[key];
        return acc;
      }, {});

      if (
        !isEqual(selectedProductKeys, cloneSelectedProductKeys) &&
        this.allShelves.length > 1
      ) {
        let _this = this;
        this.$confirm({
          title: "Apply Changes Across All Shelves",
          class: "ant-modal--dark",
          content: () => (
            <div>
              Would you like to apply these changes to this UPC across all
              shelves?
            </div>
          ),
          onOk() {
            const updateProduct = (productsArray, productIndex) => {
              productsArray[productIndex] = _this.selectedProduct;
            };

            _this.allShelves.forEach((shelf) => {
              shelf.bays.forEach((bay) => {
                bay.shelves.forEach((shelve) => {
                  shelve.products.forEach((product, index) => {
                    if (product.upc === _this.selectedProduct.upc) {
                      updateProduct(shelve.products, index);
                    } else if (product.stack) {
                      const stackProductIndex = product.stack.findIndex(
                        (stackedProduct) =>
                          stackedProduct.upc === _this.selectedProduct.upc
                      );

                      if (stackProductIndex !== -1) {
                        updateProduct(product.stack, stackProductIndex);
                      }
                    }
                  });
                });
              });
            });

            _this.productPreviewModal = false;
          },
          okText: "Yes",
          cancelText: "No",
          onCancel() {
            _this.productPreviewModal = false;
          },
        });
      } else {
        this.productPreviewModal = false;
      }
    },
    addProduct(product) {
      this.addNewProductModal = false;
      // Ensure the structure exists
      if (
        !this.shelf.bays ||
        !this.shelf.bays[0] ||
        !this.shelf.bays[0].shelves ||
        !this.shelf.bays[0].shelves[0]
      ) {
        return;
      }

      // Initialize products array if it doesn't exist
      if (!Array.isArray(this.shelf.bays[0].shelves[0].products)) {
        this.shelf.bays[0].shelves[0].products = [];
      }

      // Add the product to the beginning of the products array
      if (this.shelf.dataSource === "CSV" || this.shelf.dataSource === "") {
        this.shelf.bays[0].shelves[0].products.unshift(product);
      } else {
        const lastShelfIndex = this.shelf.bays[0].shelves.length - 1;
        this.shelf.bays[0].shelves[lastShelfIndex].products.unshift(product);
      }
    },
    findUniqueBrandNames() {
      let brandNamesSet = new Set();
      if (this.shelf && Array.isArray(this.shelf.bays)) {
        this.shelf.bays.forEach((bay) => {
          if (bay && Array.isArray(bay.shelves)) {
            bay.shelves.forEach((shelf) => {
              if (shelf && Array.isArray(shelf.products)) {
                shelf.products.forEach((product) => {
                  if (product && product.brandName) {
                    brandNamesSet.add(product.brandName);
                  }
                });
              }
            });
          }
        });
      }
      this.uniqueBrandNames = Array.from(brandNamesSet);
    },
    setCurrency(currency) {
      for (const shelf of this.allShelves) {
        shelf.currencySymbol = currency;
      }
    },
    updateProductImageRotation(images) {
      this.selectedProduct.thumb_rotated_image_url = `${
        images.thumb
      }?cacheBuster=${new Date().getTime()}`;
      this.selectedProduct.rotated_image_url = `${
        images.image
      }?cacheBuster=${new Date().getTime()}`;
      this.calculateShelfLayoutAndDimensions();
    },
  },

  beforeRouteLeave(to, from, next) {
    this.stopUploadProcessLoading = true;
    this.uploadProcessLoading = [];
    this.productPopoverVisible = null;
    next();
  },
  activated() {
    this.stopUploadProcessLoading = false;
    this.user = jsUtils.getUserInfo();
    this.allShelves = [];
    this.projectName = decodeURIComponent(this.$route.params.projectName);
    this.shelfId = this.$route.params.id;
    this.getProjectDetails();
  },

  mounted() {
    // setTimeout(() => {
    //   Sortable.create(document.getElementById("sortable-list"), {
    //     multiDrag: true,
    //     selectedClass: "sortable-selected",
    //     animation: 150,
    //   });
    // }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  position: absolute;
  border: 1px solid red;
}
.shelf-builder {
  text-align: left;
  position: relative;
  background: #1a1b1d;
  overflow: auto;
  padding-top: 141px;
  height: 100%;
  &--hide-price {
    .shelf-builder__item-price {
      display: none;
    }
  }
  &--light {
    .shelf-builder__inner-col {
      background: white;
    }
  }
  &__loading {
    position: fixed;
    top: 0;
    left: 96px;
    width: 100%;
    bottom: 0;
    background: #1a1b1d;
    color: #fff;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loader {
      margin-top: 30px;
      font-size: 14px;
      ::v-deep .circle-loader {
        display: none;
      }
    }
  }
  &__tools {
    display: flex;
    padding: 15px 20px;
    gap: 10px;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 75px;
    z-index: 99;
    background: #2e2e2e;
    .ant-btn {
      svg {
        margin-right: 8px;
      }
      &[disable] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &__tools-slider {
    color: #c8c8c8;
    font-size: 14px;
    gap: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .ant-slider {
      width: 140px;
      margin-right: 0;
    }
  }
  &__active-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 71px;
    left: 96px;
    right: 0;
    z-index: 99;
    background: #1f2022;
  }
  &__inner {
    display: flex;
    border-right: 6px solid #b6b6b6;
    border-left: 6px solid #b6b6b6;
    box-shadow: 0px 30px 20px -10px rgba(0, 0, 0, 0.1);
    padding-bottom: 55px;
    background: #000;
  }
  &__inner-col {
    min-width: 50px;
    background: url("/images/transparentShelfBcg.png") repeat;
    background-size: 15px 15px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    &--no-reverse {
      flex-direction: column;
    }
    &:after {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      border-right: 2px solid #959595;
      content: "";
    }
    &:last-child {
      border-right-color: transparent;
    }
    > div {
      &:last-child {
        .shelf-builder__row {
          box-shadow: none;
        }
      }
    }
  }
  &__row {
    display: flex;
    align-items: flex-end;
    min-height: 40px;
    position: relative;
    background: url("/images/shelf-price-bcg.jpg") repeat-x;
    background-position: bottom;
    &:after {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 50%;
      content: "";
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.45) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      pointer-events: none;
    }
    ::v-deep .ant-checkbox-wrapper {
      font-size: 13px;
      font-weight: 500;
    }
  }
  &__item {
    display: flex;
    //align-items: flex-end;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    user-select: none;
    &.deki {
      background: red;
    }
  }
  &__item-content {
    //display: contents;
    &:hover,
    &.is-active {
      &::after {
        opacity: 1;
      }
      .shelf-builder__item-resize {
        opacity: 1;
        pointer-events: all;
      }
    }
    &.multi-select {
      &::after {
        opacity: 1;
        box-shadow: inset 0px 0px 0px 2px rgba(255, 0, 67, 1);
      }
    }
    &.is-deep-dive {
      &::after {
        opacity: 0.65 !important;
        background: var(--blue);
      }
    }
    &.no-image {
      background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' opacity='0.5' d='M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z'/%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3C/svg%3E")
        center no-repeat #d17903;
      background-size: 60% 60%;
      display: flex;
      height: 100%;
      width: 100%;
      position: relative !important;
      left: auto;
      top: auto;
      transform: none;
    }
    &:after {
      pointer-events: none;
      position: absolute;
      opacity: 0;
      inset: 0;
      content: "";
      box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
      z-index: 1;
    }
  }
  &__item-resize {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    cursor: nesw-resize;
    right: -4px;
    top: -11px;
    border-top: 18px solid transparent;
    border-bottom: 21px solid transparent;
    z-index: 1;
    border-left: 20px solid WHITE;
    transform: rotate(-43deg);
    z-index: 10;
  }
  &__item-image {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 100%;
    width: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__item-price {
    position: absolute;
    left: 50%;
    bottom: -31px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    background: #f4f4f4;
    color: #000;
    padding: 1px 2px;
    font-weight: 500;
    line-height: normal;
    transform: translateX(-50%);
    border: 1px solid #333;
  }
  &__item-image-row {
    height: 100%;
    display: flex;
    align-items: flex-end;
    svg {
      position: absolute;
      pointer-events: none;
      width: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      path {
        stroke: #dfb781;
      }
    }
  }
}

.popover-enter-active,
.popover-leave-active {
  transition: all 0.3s ease;
}
.popover-enter,
.popover-leave-to {
  opacity: 0;
}

::v-deep .modal-uploader {
  .ant-upload {
    height: 120px;
  }
}

$thickness: 5px;
$duration: 2800;
$delay: $duration/6;

@mixin dotts-list($size, $dot, $base, $accent) {
  background: $base;
  background-image: radial-gradient($accent $dot, transparent 0);
  background-size: $size $size;
  background-position: 0 -2.5px;
}

.products-loader {
  position: relative;
  transform: scale(0.8);
  &__list {
    margin: 0 auto;
    width: 300px;
    padding: 0;
  }
  &__list-item {
    position: absolute;
    top: -70px;
    box-sizing: border-box;
    list-style: none;
    width: 60px;
    height: 70px;
    opacity: 0;
    background-color: var(--blue);
    border: 4px solid #fff;
    transform-origin: bottom left;
    transform: translateX(300px);
    animation: products-move #{$duration}ms linear infinite;

    &.first {
      top: -100px;
      height: 100px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: $thickness;
        background-color: #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.second,
    &.fifth {
      &:before,
      &:after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: $thickness * 3.5;
        border-top: $thickness solid #fff;
        border-bottom: $thickness solid #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.third {
      &:before,
      &:after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 10px;
        left: 9px;
        width: 15px;
        height: 15px;
        border: $thickness solid #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.fourth {
      top: -110px;
      height: 110px;

      &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        height: $thickness * 3.5;
        border-top: $thickness solid #fff;
        border-bottom: $thickness solid #fff;
      }
    }

    &.fifth {
      top: -100px;
      height: 100px;
    }

    &.sixth {
      top: -116px;
      height: 115px;

      &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        bottom: 31px;
        left: 0px;
        width: 100%;
        height: $thickness;
        background-color: #fff;
      }
    }

    &:nth-child(2) {
      animation-delay: #{$delay * 1}ms;
    }

    &:nth-child(3) {
      animation-delay: #{$delay * 2}ms;
    }

    &:nth-child(4) {
      animation-delay: #{$delay * 3}ms;
    }

    &:nth-child(5) {
      animation-delay: #{$delay * 4}ms;
    }

    &:nth-child(6) {
      animation-delay: #{$delay * 5}ms;
    }
  }
  &__shelf {
    width: 300px;
    height: $thickness;
    margin: 0 auto;
    background-color: #fff;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      @include dotts-list(10px, 30%, #242527, rgba(255, 255, 255, 1));
      top: 200%;
      left: 5%;
      animation: dotts-move #{$duration/10}ms linear infinite;
    }

    &:after {
      top: 400%;
      left: 7.5%;
    }
  }
}

@keyframes dotts-move {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 10px;
  }
}

@keyframes products-move {
  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }

  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }

  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }

  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }

  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }

  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }

  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }

  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }

  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }

  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }

  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(0px) rotateZ(-90deg);
  }
}
</style>
