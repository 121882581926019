var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload"},[_c('div',{staticClass:"upload__close",on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"upload__overlay"}),_c('div',{staticClass:"upload__inner position-r"},[_c('h2',[_vm._v("Update")]),_c('ul',{staticClass:"upload__tabs"},[_c('li',{class:{ active: _vm.activeTab === 'shelf' },on:{"click":function($event){_vm.activeTab = 'shelf'}}},[_vm._v(" Shelf Images ")]),_c('a-tooltip',{attrs:{"placement":"right"}},[(_vm.inDeepDiveProducts.length === 0)?_c('template',{slot:"title"},[_vm._v(" Please select one or more DeepDive products to proceed ")]):_vm._e(),(!_vm.hideProductsTab)?_c('li',{class:{ active: _vm.activeTab === 'products' },attrs:{"disabled":_vm.inDeepDiveProducts.length === 0},on:{"click":function($event){_vm.inDeepDiveProducts.length !== 0
              ? ((_vm.activeTab = 'products'), _vm.matchAndFillDeepDives())
              : (_vm.activeTab = 'shelf')}}},[_vm._v(" Product Images ")]):_vm._e()],2)],1),_c('div',{staticClass:"upload__inner-scroll"},[(_vm.activeTab === 'shelf')?_c('div',{staticClass:"projects flex"},[_c('div',{staticClass:"projects__images"},[_c('h3',{staticClass:"projects__images-title"},[_vm._v("Shelf Images")]),_vm._l((_vm.allShelves),function(shelf,index){return _c('div',{key:'shelfImg' + shelf.shelfType,staticClass:"projects__images-wrapper"},[_c('h3',[_vm._v(_vm._s(shelf.shelfType))]),_c('div',{staticClass:"flex",style:({
                height:
                  _vm.gcsProjects[index] && _vm.gcsProjects[index].ShelfImageList
                    ? _vm.gcsProjects[index].ShelfImageList.length * 50 +
                      (_vm.gcsProjects[index].ShelfImageList.length - 1) * 5 +
                      'px'
                    : '50px',
              })},[_c('div',{staticClass:"projects__images-item"},[_c('img',{attrs:{"src":shelf.shelfImageURLUnpricedThumb,"alt":" "}})])])])})],2),_c('div',{staticClass:"projects__urls"},[_c('h3',{staticClass:"projects__images-title"},[_vm._v("Image Links From CIP")]),_vm._l((_vm.gcsProjects),function(project,index){return _c('div',{key:index,staticClass:"projects__urls-wrapper"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('h3',[_vm._v(_vm._s(project.ShelfName))])]),_c('draggable',{staticClass:"projects__urls-item",attrs:{"list":project.ShelfImageList,"handle":".js-draggable-handle","group":"shelfImages","tag":'div',"item-key":"ShelfImageName","move":_vm.onDragMove},on:{"start":_vm.onStart,"end":_vm.onEnd}},_vm._l((project.ShelfImageList),function(item,itemIndex){return _c('div',{key:'item-' + itemIndex,staticClass:"js-draggable-handle projects__urls-item-inner"},[_c('div',{staticClass:"icon-draggable-handle"},_vm._l((8),function(item){return _c('i',{key:item})}),0),_c('font-awesome-icon',{attrs:{"icon":"check-circle"}}),_c('div',_vm._l((item.ShelfImageURL),function(url){return _c('p',{key:url},[_vm._v(" "+_vm._s(url)+" ")])}),0),(item.ShelfImageName)?_c('h4',[_vm._v(_vm._s(item.ShelfImageName))]):_vm._e()],1)}),0)],1)})],2)]):_vm._e(),(_vm.activeTab === 'products')?_c('div',{staticClass:"projects projects--products flex"},[_c('div',{staticClass:"projects__images"},[_c('h3',{staticClass:"projects__images-title"},[_vm._v("Product Images")]),_vm._l((_vm.products),function(group,shelfType,index){return _c('div',{key:'product-' + shelfType,staticClass:"projects__images-wrapper"},[_c('h3',[_vm._v(_vm._s(shelfType))]),_c('div',{style:(("min-height: " + (_vm.gcsProjects[index] &&
                _vm.gcsProjects[index].DeepDive &&
                _vm.gcsProjects[index].DeepDive.length > 0
                  ? _vm.gcsProjects[index].DeepDive.length * 50 +
                    (_vm.gcsProjects[index].DeepDive.length - 1) * 5
                  : 50) + "px"))},_vm._l((group),function(product){return _c('div',{key:product.upc,staticClass:"projects__images-item"},[_c('img',{attrs:{"src":product.rotated_image_url || product.productURL,"alt":product.upc}})])}),0)])})],2),_c('div',{staticClass:"projects__urls"},[_c('h3',{staticClass:"projects__images-title"},[_vm._v("Image Links From CIP")]),_vm._l((_vm.gcsProjects),function(project,index){return _c('div',{key:index,staticClass:"projects__urls-wrapper"},[_c('h3',[_vm._v(_vm._s(project.ShelfName))]),_c('draggable',{staticClass:"projects__urls-item",class:{ empty: project.DeepDive.length === 0 },style:(("min-height: " + (_vm.products[project.TempShelfName]
                  ? _vm.products[project.TempShelfName].length * 50 +
                    (_vm.products[project.TempShelfName].length - 1) * 5
                  : '50px') + "px;")),attrs:{"list":project.DeepDive,"handle":".js-product-draggable-handle","group":"shelfProducts","tag":'div',"item-key":"DeepDiveName","force-fallback":true},on:{"end":_vm.onProductEnd}},_vm._l((project.DeepDive),function(item,itemIndex){return _c('div',{key:'product-' + itemIndex,staticClass:"js-product-draggable-handle projects__urls-item-inner",class:{
                  empty: !item.DeepDiveImageURL && !item.DeepDiveName,
                }},[_c('div',{staticClass:"icon-draggable-handle"},_vm._l((8),function(item){return _c('i',{key:item})}),0),(item.DeepDiveImageURL)?_c('p',[_vm._v(" "+_vm._s(item.DeepDiveImageURL)+" ")]):_vm._e(),(item.DeepDiveName)?_c('h4',[_vm._v(_vm._s(item.DeepDiveName))]):_vm._e()])}),0)],1)})],2)]):_vm._e()]),_c('div',{staticClass:"upload__controls flex justify-end"},[_c('a-button',{staticClass:"b-radius-40",attrs:{"type":"dark"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('a-button',{staticClass:"b-radius-40",attrs:{"type":"primary"},on:{"click":_vm.save}},[(_vm.loading)?_c('Loader',{staticClass:"space-0",attrs:{"text":""}}):_c('span',[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" Save")],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }